import { useState } from 'react'
import Faq from './components/FAQ'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'

function FaqIndex() {
  return (
    <div className="relative overflow-x-hidden text-center w-screen flex flex-col">
      <Navbar />
      <Faq />
      <Footer />
    </div>
  )
}

export default FaqIndex
