import { useLocation } from 'react-router-dom'

import Navbar from '../../../common/Navbar'
import Footer from '../../../common/Footer'

import ErrorPage from '../../error'
import Food from './components/Food'
import Health from './components/Health'
import ClimateEnvironment from './components/ClimateChange'

function ClimateAndEnvironment() {
  const { pathname } = useLocation()

  const renderSector = () => {
    switch (pathname) {
      case '/sector/climate-change-environmental-sustainability':
        return <ClimateEnvironment />
      case '/sector/food-production-systems-food-security':
        return <Food />
      case '/sector/health-wellness':
        return <Health />
      default:
        return <ErrorPage />
    }
  }
  return (
    <div className="relative overflow-x-hidden w-screen flex flex-col">
      <Navbar />
      {renderSector()}
      {/* <Footer /> */}
    </div>
  )
}

export default ClimateAndEnvironment
