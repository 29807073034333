import Home from './screens/home'

function App() {
  return (
    <div className="font-Roboto overflow-x-hidden flex flex-col items-center">
      <Home />
    </div>
  )
}

export default App
