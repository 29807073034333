import React from 'react'
import { PRIVACY_CONTENT } from '../../../assets/content'
import "../../home/components/style.css"

const Privacy = () => {
  const websiteLink = () => {
    return (
      <span
        onClick={() => window.open('https://www.macrocosmoscreations.com')}
        className="text-[#82A800] cursor-pointer"
      >
        www.macrocosmoscreations.com
      </span>
    )
  }
  const mailLink = () => {
    return (
      <span
        onClick={() => window.open('mailto://contact@macrocosmoscreations.com')}
        className="text-[#82A800] cursor-pointer"
      >
        contact@macrocosmoscreations.com
      </span>
    )
  }
  return (
    <>
      <h1 className=" newfont_h2 font-bold text-xl text-center pt-24">Privacy Policy</h1>
      <section className=" newfont_h2 text-left text-justify  p-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <p>
          This privacy policy [for Macrocosmos Creations Private Limited
          (“Company”)] sets out how {websiteLink()} uses and protects any
          information that you give when you use this website.
          <br />
          <span
            onClick={() => window.open('https://www.macrocosmoscreations.com')}
            className="text-[#82A800] cursor-pointer"
          >
            www.macrocosmoscreations.com
          </span>{' '}
          is committed to ensuring that your privacy is protected. Should we ask
          you to provide certain information by which you can be identified when
          using this website, then you can be assured that it will only be used
          in accordance with this privacy statement. <br />
          {websiteLink()} may change this policy from time to time by updating
          this page. You should check this page from time to time to get
          yourself updated with any changes.
        </p>
        <br />
        <h2 className="font-bold">What we collect:</h2>
        <p>
          <h2>
            We may collect the following information, which may include (but is
            not limited to):
          </h2>
          <ol className="list-outside list-[decimal]">
            <p className="pl-8">
              {PRIVACY_CONTENT.what_we_collect.map((info, index) => (
                <li key={'collect' + index}>{info}</li>
              ))}
            </p>
          </ol>
        </p>
        <br />
        <h2 className="font-bold">How do we collect personal data:</h2>
        <p>
          <h2>This Privacy Policy covers any Personal Data provided to us:</h2>
          <ol className="list-outside list-[decimal]">
            <p className="pl-8">
              {PRIVACY_CONTENT.personal_data.main_ways.map((info, index) => (
                <li key={'main_ways' + index}>{info}</li>
              ))}
            </p>
          </ol>
          Some of the other ways we may collect Personal Data shall include (but
          is not limited to):
          <ol className="list-outside list-[decimal]">
            <p className="pl-8">
              {PRIVACY_CONTENT.personal_data.other_ways.map((info, index) => (
                <li key={'other_ways' + index}>{info}</li>
              ))}
            </p>
          </ol>
        </p>
        <br />
        <h2 className="font-bold">How do we use cookies:</h2>
        <h2>Please refer to the cookies policy on our website.</h2>
        <br />
        <h2 className="font-bold">
          What we do with the information we gather:
        </h2>
        <h2>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </h2>
        {
          <ul className="list-outside list-disc">
            <p className="pl-8">
              {PRIVACY_CONTENT.waht_we_do.map((info, index) => (
                <li key={'do' + index}>{info}</li>
              ))}
            </p>
          </ul>
        }
        <br />
        <h2 className="font-bold">Controlling your personal information:</h2>
        <h2>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </h2>
        {
          <ul className="list-outside list-disc">
            <p className="pl-8">
              {PRIVACY_CONTENT.controlling_your_personal_info.map(
                (info, index) => (
                  <li key={'control' + index}>
                    {info} {index === 1 && mailLink()}
                    {index === 1 && '.'}
                  </li>
                )
              )}
            </p>
          </ul>
        }
        <br />
        <h2 className="font-bold">
          To whom do we disclose your personal data:
        </h2>
        <h2>We will not trade or sell your Personal Data to third parties.</h2>
        <h2>
          Your Personal Data shall only be disclosed or transferred to the
          following third parties appointed or authorized by the Company for the
          fulfillment of the Purpose of:
        </h2>
        <ol className="list-outside list-[decimal]">
          <p className="pl-8">
            {PRIVACY_CONTENT.whom_do_we_disclose_personal_data.map(
              (info, index) => (
                <li key={'disclose' + index}>{info}</li>
              )
            )}
          </p>
        </ol>
        <br />
        <p>{PRIVACY_CONTENT.para_1}</p>
        <br />
        <h2 className="font-bold">
          How long will we retain your personal data:{' '}
        </h2>
        <p>{PRIVACY_CONTENT.how_long_data_retains}</p>
        <br />
        <h2 className="font-bold">Links to other websites:</h2>
        <p>{PRIVACY_CONTENT.links_to_other_websites}</p>
        <br />
        <h2 className="font-bold">Security:</h2>
        <p>
          {PRIVACY_CONTENT.security.first_para}
          <br />
          At the same time, {websiteLink()} cannot in good faith guarantee that
          data breach is not possible since no security measure is able to
          provide absolute protection. It is advised for Users to take their own
          safety measures such as 2-Factor Authentication or other measures not
          provided by {websiteLink()}
          <br />
          If you suspect that your account information or your personal data has
          been compromised, you should contact us at {mailLink()} as soon as
          possible.
          {PRIVACY_CONTENT.security.last_para}
        </p>
        <br />
        <h2 className="font-bold">Contact us:</h2>
        <p>
          {PRIVACY_CONTENT.contact_us} {mailLink()}.
        </p>{' '}
        <br />
        <p className="font-bold">Last Revised - Mar 2nd, 2023</p>{' '}
      </section>
    </>
  )
}

export default Privacy
