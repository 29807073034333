import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillRedditCircle,
  AiFillYoutube,
  AiOutlineLinkedin,
} from 'react-icons/ai'
import { FaTelegram } from 'react-icons/fa'
import { BsDiscord } from 'react-icons/bs'



function SocialMedia() {
  return (
    <div className="bg-[#008037]">
      <div className="grid grid-rows-2 grid-cols-4 md:grid-rows-1 md:grid-cols-8 gap-2 py-5 md:text-center px-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#20f4ff]  font-semibold cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.facebook.com/profile.php?id=100089788947365',
              '_blank'
            )
          }
        >
          <AiFillFacebook size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#C13584] font-semibold cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.instagram.com/macrocosmoscreations/',
              '_blank'
            )
          }
        >
          <AiFillInstagram size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff]  font-semibold cursor-pointer pt-[5px]"
          onClick={() =>
            window.open('https://twitter.com/MacroCosmosCPVT?s=20', '_blank')
          }
        >
               <svg width="28" height="28" viewBox="0 0 300 300" version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={(e) => e.currentTarget.querySelector('path').style.fill = '#000000'}
        onMouseLeave={(e) => e.currentTarget.querySelector('path').style.fill = '#ffffff'}
      >
        <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" fill="#ffffff" />
      </svg>
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#20f4ff] font-semibold cursor-pointer"
          onClick={() =>
            window.open('https://t.me/+t2mu9cIXTwQ3MmU0', '_blank')
          }
        >
          <FaTelegram size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#FF4500] font-semibold cursor-pointer"
          onClick={() =>
            window.open('https://www.reddit.com/user/MCC-PVT-2022', '_blank')
          }
        >
          <AiFillRedditCircle size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#20f4ff]  font-semibold cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.linkedin.com/company/macrocosmos-creations/',
              '_blank'
            )
          }
        >
          <AiOutlineLinkedin size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#20f4ff] font-semibold cursor-pointer"
          onClick={() => window.open('https://discord.gg/yHsFDQGG7K', '_blank')}
        >
          <BsDiscord size={40} />
        </div>
        <div
          className="mx-auto text-xl md:text-2xl text-[#ffffff] hover:text-[#FF0000] font-semibold cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.youtube.com/channel/UCvA6-vU3syR0Ue7RTf5eJ7A',
              '_blank'
            )
          }
        >
          <AiFillYoutube size={40} />
        </div>
      </div>
    </div>
  )
}

export default SocialMedia
