import { useLocation } from 'react-router-dom'

import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import CosmosE from './components/CosmosE'
import CosmosF from './components/CosmosF'
import CosmosH from './components/CosmosH'
import ErrorPage from '../error'

function ProductsPage() {
  const { pathname } = useLocation()
  const renderProducts = () => {
    switch (pathname) {
      case '/products/cosmos-e':
        return <CosmosE />
      case '/products/cosmos-f':
        return <CosmosF />
      case '/products/cosmos-h':
        return <CosmosH />
      default:
        return <ErrorPage />
    }
  }
  return (
    <>
      <Navbar />
      {renderProducts()}
      <Footer />
    </>
  )
}

export default ProductsPage
