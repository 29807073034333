import Cookies from './components/Cookies'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'

function CookiesIndex() {
  return (
    <div className="relative overflow-x-hidden text-center w-screen flex flex-col">
      <Navbar />
      <Cookies />
      <Footer />
    </div>
  )
}

export default CookiesIndex
