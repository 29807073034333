import environmentSustainabilityImage from '../../../../assets/images/sectors/bgImgs/widescreen_envt_sust_bg.png'
import dmrv from '../../../../assets/images/sectors/dmrv.png'
// import carbon_credit_offset from '../../../../assets/images/sectors/carbon_credit_offset.jpg'
import climate_change from '../../../../assets/images/sectors/climate_change.png'
// import digital_certificate from '../../../../assets/images/sectors/digital_certificate.jpg'
import Modal from '../../../../common/Modal'
import { useState } from 'react'
import '../../../home/components/style.css'
// import videoBg from '../../../../assets/images/videos/Mct_video2.mp4'
import videoBg from '../../../../assets/images/videos/climate_change.mp4'
import carbon_credit_offset from '../../../../assets/images/sectors/carbon_credit_offset-2.png'
import digital_certificate from '../../../../assets/images/sectors/digital_certificate-2.png'
const ClimateEnvironment = () => {
  const [envSustModal, setEnvSustModal] = useState(false)
  const [envSustContent, setEnvSustContent] = useState('')
  const [envSustTitle, setEnvSustTitle] = useState('')

  const renderEnvSust = () => {
    return (
      <Modal
        isOpen={envSustModal}
        headerTitle={envSustTitle}
        modalWidth="w-3/5 newfont_h2 "
        primaryButtonText="Okay"
        handleClose={() => {
          setEnvSustModal(false)
        }}
        handlePrimaryClick={() => {
          setEnvSustModal(false)
        }}
        showPrimary={false}
        showSecondary={false}
      >
        <div className="flex flex-col lg:flex-row mt-2 items-center p-4 text-justify newfont_h2 ">
          {/* <div className="ml-2">
            <img
              alt="mat_logo"
              className="w-[60px] sm:w-[100px] lg:w-[850px] xl:w-[700px]"
              src={mctlogo}
            />
          </div>
          <div className="p-4 max-h-[40vh] overflow-auto text-justify">
            {OUR_CARBON_TOKEN_CONTENT}
          </div> */}
          {envSustContent}
        </div>
      </Modal>
    )
  }

  return (
    <>
      <div className="flex flex-col text-left  self-center mt-[5rem] ">
      <div className='relative'>
        <div
          className="w-screen h-[30vh] lg:h-[100vh] overflow-hidden"
        >
        <video autoPlay muted loop >
        <source src="https://mcc-public-docs.s3.amazonaws.com/MCC+-+Climate-Change.mp4" type="video/mp4" />
      </video>
            </div>
          </div>
      </div>
      <div className=''>
        <div>
        <h1 className=" text-center  pt-2 text-white leading-tight  newfont_h1">
          {/* Climate Change and Environmental Sustainability */}
          <span className="text-[#004AAD]">Climate Change</span>
          <span className="text-[#00C2CB]">{` and `}</span>
          <span className="text-[#008037]">Environmental Sustainability</span>
        </h1>
      </div>

      <section className="p-4 max-w-full sm:max-w-[676px] lg:max-w-[978px] mx-auto">
      <p className="p-4 newfont_h2  text-justify " >
          Climate change is one of the most critical challenges faced by our planet today. It's a problem caused by human activities that refers to the long-term changes in temperature, precipitation, and weather patterns. Environmental sustainability is the solution to mitigate the problem and protect the planet. It refers to the practices of preserving the natural environment for future generations by taking actions to reduce our impact on livelihood.
      </p>
        <div>
          <h2 className=" leading-tight text-center px-4 mt-0 mb-2 newfont_h1">
            How MCC Works in Climate Change and Environmental Sustainability
          </h2>
          <div className="p-4 grid lg:grid-cols-2 gap-4 text-justify  newfont_h2 ">
            <p className=' ' >
              At MCC, we are committed to leading the way in the fight against
              climate change and promoting environmental sustainability. We are
              proud to offer cutting-edge solutions for carbon management. Our
              approach includes tokenization, which ensures transparency and
              accountability in carbon trading. This innovative technology,
              along with other advanced tools such as blockchain, IoT, AI, and
              ML, allows us to provide accurate and efficient carbon management
              solutions.
            </p>
            <p className='' >
              We work closely with our clients to provide a digital and advanced
              platform and develop tailored solutions that fit their unique
              needs and goals. Transparency and sustainability are at the
              forefront of everything we do, whether we are calculating
              greenhouse gas emissions, facilitating carbon trading on our
              marketplace, offsetting carbon emissions including digital
              monitoring reporting verification (DMRV) services to ensure the
              accuracy and reliability of carbon credits and emissions data.
            </p>
          </div>
        </div>
      </section>
      </div>
      <div className="mb-4 w-[80%] xl:w-[70%] mx-auto max-w-[1440px] text-xs sm:text-sm lg:text-lg self-center grid grid-cols-2 lg:grid-cols-4 gap-4 place-content-center place-items-center ">
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer newfont_h2 "
          onClick={() => {
            setEnvSustModal(true)
            setEnvSustTitle('Climate Change')
            setEnvSustContent(
              "Climate change is the long-term alteration in global weather patterns. It is causing rising sea levels and accelerating the melting of mountain glaciers and ice sheets in Greenland, Antarctica and the Arctic. Greenhouse gases like carbon dioxide, methane, and nitrous oxide are causing the Earth's temperature to increase, leading to changes in the timing of plant and flower blooming."
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={climate_change}
            alt="climate_change"
            className="circular-image pt-1  object-contain "
          />
          </div>
          <p className="font-bold newfont_h2">Climate Change</p>
        </div>
        <div
          className="text-center flex flex-col items-center  mx-2 cursor-pointer newfont_h2 "
          onClick={() => {
            setEnvSustModal(true)
            setEnvSustTitle('Carbon Credit & Offset')

            setEnvSustContent(
              'Carbon credits and offsetting are vital for reducing greenhouse gases. They let organizations offset their carbon footprint through investment in projects such as renewable energy, organic farming, reforestation, and other nature-based solutions. A single credit equals one ton of CO2, making it a powerful tool for fighting climate change. Carbon credits provide entities with an opportunity to achieve their sustainability goals and contribute to a greener future.'
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={carbon_credit_offset}
            alt="carbon_credit_offset"
            className=" circular-image  object-contain  "
          />
          </div>
          <p className=" font-bold newfont_h2">Carbon Credit & Offset</p>
        </div>
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer newfont_h2 "
          onClick={() => {
            setEnvSustModal(true)
            setEnvSustTitle('DMRV')
            setEnvSustContent(
              "Digital Monitoring Reporting and Verification (DMRV), uses digital technologies to track and report on environmental data, particularly greenhouse gas emissions. This process ensures compliance with regulations and sustainability targets by accurately measuring and reporting an organization's carbon footprint. By streamlining the reporting process, reducing errors, and improving transparency, DMRV enables organizations to effectively monitor and manage their environmental impact."
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={dmrv}
            alt="dmrv"
            className="circular-image pt-1 object-contain "
            // w-1/2 md:w-1/4 lg:w-1/2  xl:w-1/4 
          />
          </div>
          <p className="font-bold">DMRV</p>
          
        </div>
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer newfont_h2 "
          onClick={() => {
            setEnvSustModal(true)
            setEnvSustTitle('Digital Certificate')

            setEnvSustContent(
              'A digital certificate in carbon credits and offsets is a blockchain-based record that tracks the ownership and transaction history of carbon credits or offsets. It acts as proof of carbon offsetting and enables secure and transparent trading. The blockchain ensures tamper-proof and verifiable data, providing a reliable method for tracking and verifying carbon credits and offsets.'
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={digital_certificate}
            alt="digital_certificate"
            className=" circular-image pt-2 object-contain"
          />
          </div>
          <p className="font-bold newfont_h2">Digital Certificate</p>
          
        </div>
      </div>
      {envSustModal && renderEnvSust()}
    </>
  )
}

export default ClimateEnvironment
