const CosmosE = () => {
  return (
    <>
      <div className="py-32 text-center px-4 mx-auto text-xl md:text-3xl">
        Coming Soon!
      </div>
    </>
  )
}

export default CosmosE
