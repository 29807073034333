import React from 'react'
import { COOKIES_CONTENT } from '../../../assets/content'
import '../../home/components/style.css'

const Cookies = () => {
  return (
    <>
      <h1 className="font-bold text-xl text-center newfont_h2 mt-[9%] lg:mt-[6.6%] ">Cookies Policy</h1>
      <section className="text-left text-justify lg:text-lg font-normal p-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <div>
          <h2 className="font-bold newfont_h2">Introduction:</h2>
          <p className='newfont_h2'>
            Our website [Macrocosmos Creations Private Limited (“Company”):{' '}
            <span
              onClick={() =>
                window.open('https://www.macrocosmoscreations.com')
              }
              className="text-[#82A800] cursor-pointer"
            >
              www.macrocosmoscreations.com
            </span>
            ] uses cookies to enhance your user experience and to improve the
            overall quality of our services. This policy provides information
            about what cookies are, how we use them on our website
            www.macrocosmoscreations.com, and how you can control them. By using
            our website, you consent to our use of cookies in accordance with
            this policy.
          </p>
        </div>
        <br />
        <div>
          <h2 className="font-bold newfont_h2">What are cookies?</h2>
          <p className='newfont_h2'>{COOKIES_CONTENT.DEFINITION}</p>
        </div>
        <br />
        <div>
          <h2 className="font-bold newfont_h2">Types of Cookies We Use:</h2>
          <p className='newfont_h2'>We use the following types of cookies on our website:</p>
          <ul className="list-disc list-outside">
            <p className="pl-8 newfont_h2">
              {COOKIES_CONTENT.TYPES_OF_COOKIES.map((cookie, index) => (
                <li key={'cookieTypes' + index}>
                  <span className="font-bold">{cookie.title}</span>{' '}
                  {cookie.desc}
                </li>
              ))}
            </p>
          </ul>
        </div>
        <br />
        <div>
          <h2 className="font-bold newfont_h2">How to Manage Cookies:</h2>
          <p className='newfont_h2'>{COOKIES_CONTENT.HOW_TO_MANAGE[0]}</p>
          <p className='newfont_h2'>{COOKIES_CONTENT.HOW_TO_MANAGE[1]}</p>
        </div>
        <br />
        <div>
          <h2 className="font-bold newfont_h2">Contact us:</h2>
          <p className='newfont_h2'>
            If you have any questions or concerns about our use of cookies,
            please contact us at{' '}
            <span
              onClick={() =>
                window.open('mailto://contact@macrocosmoscreations.com')
              }
              className="text-[#82A800] cursor-pointer"
            >
              contact@macrocosmoscreations.com
            </span>
            .
          </p>
        </div>
        <br />
        <p className="font-bold newfont_h2">Last Revised - Mar 2nd, 2023</p>{' '}
      </section>
    </>
  )
}

export default Cookies
