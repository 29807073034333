import voiceWellness from '../../../assets/images/cosmos/voice_wellness.png'
import insta from '../../../assets/images/cosmos/insta.png'
import linkedin from '../../../assets/images/cosmos/linkedin.png'
import googlescholar from '../../../assets/images/cosmos/googlescholar.png'
import youTubeIcon from '../../../assets/images/cosmos/YouTubeIcon.png'
import '../../home/components/style.css'

const CosmosH = () => {
  return (
    <>
      <section className="p-4 pt-0 max-w-full sm:max-w-[676px] lg:max-w-[978px] mx-auto lg:text-lg text-justify mt-28">
        <img
          src={voiceWellness}
          alt="voiceWellness"
          className="object-contain w-[40%] lg:w-[20%] mx-auto my-3"
        />
        <p className='newfont_h2'>
          The Voice Wellness Centre™, a unit of MacroCosmos Creations Private
          Limited, is committed to providing clinical services, fostering
          research collaborations, and offering valuable learning tools for
          voice professionals and those interested in the field.
        </p>
        <br />
        <h2 className="font-bold lg:text-lg newfont_h1">Clinical Services</h2>
        <p className='newfont_h2'>
          “Voice Therapy” helps in restoring deviant voice functions developed
          based on Evidence Based Practices (EBP). EBP combines three specific
          domains for patient care. These include clinical expertise, patient
          values and research evidence
        </p>
        <ul className="list-disc list-outside">
          <p className="pl-8 newfont_h2">
            <li>
              Detailed Voice Assessment includes evaluation of all the
              subsystems of voice (respiration, phonation, and resonance),
              perceptual, acoustic and aerodynamic analysis of voice, and self
              -evaluation and quality of life assessments.
            </li>
            <li>
              Voice Therapy involves providing treatment options, planning and
              implementing therapy, counselling and follow-up.
            </li>
          </p>
        </ul>
        <br />
        <h2 className="font-bold newfont_h1">Research Collaborations</h2>
        <ul className="list-disc list-outside">
          <p className="pl-8 newfont_h2">
            <li>
              We are open for collaborative research. Contact us if you are
              interested to carry out research studies.
            </li>
          </p>
        </ul>
        <br />
        <p className='newfont_h2'>
          For more details about our clinical services, patient testimonials,
          and research work, please{' '}
          <span
            onClick={() =>
              window.open(
                'https://drive.google.com/file/d/1xpVIDFj9jw5V76Y1RLbZO8Tb7zceoK3T/view?usp=sharing',
                '__blank'
              )
            }
            className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer newfont_h2"
          >
            Click Here
          </span>
          .
        </p>
        <br />
        <p className="text-center newfont_h2">Visit our social media channels:</p>
        <div className="mb-4 w-[50%] my-2 mx-auto max-w-[1440px] text-xs sm:text-sm lg:text-lg self-center grid grid-cols-2 lg:grid-cols-4 gap-4 place-content-center place-items-center">
          <div
            className="text-center flex flex-col items-center mx-2 cursor-pointer"
            onClick={() =>
              window.open(
                'https://www.instagram.com/the_voice_wellness_centre/?igshid=YmMyMTA2M2Y%3D',
                '__blank'
              )
            }
          >
            <img src={insta} alt="insta" className="object-contain w-2/3" />
          </div>
          <div
            className="text-center flex flex-col items-center  mx-2 cursor-pointer"
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/ketaki-vasant-phadke-phd-67b0a4155',
                '__blank'
              )
            }
          >
            <img
              src={linkedin}
              alt="linkedin"
              className="object-contain w-2/3"
            />
          </div>
          <div
            className="text-center flex flex-col items-center mx-2 cursor-pointer"
            onClick={() =>
              window.open(
                'https://scholar.google.com/citations?user=QyV1oLYAAAAJ&hl=en',
                '__blank'
              )
            }
          >
            <img
              src={googlescholar}
              alt="googlescholar"
              className="object-contain w-2/3"
            />
          </div>
          <div
            className="text-center flex flex-col items-center mx-2 cursor-pointer"
            onClick={() =>
              window.open(
                'https://www.youtube.com/channel/UCYglWjVrsgjLIBmBwBF89cg',
                '__blank'
              )
            }
          >
            <img
              src={youTubeIcon}
              alt="yieldMonitoring"
              className="object-contain w-2/3"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default CosmosH
