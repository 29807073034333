import React from 'react'
import { TERMS_AND_CONDITIONS } from '../../../assets/content'
import "../../home/components/style.css"

const TermsAndConditions = () => {
  return (
    <>
      <h1 className=" newfont_h2 font-bold  pt-28 text-xl text-center">Terms And Conditions</h1>
      <section className=" newfont_h2 text-left text-justify font-normal p-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <div>
          <h2 className="text-left">
            Terms and Conditions for Macrocosmos Creations Private Limited
            (“Company”):
          </h2>
          <div>
            {TERMS_AND_CONDITIONS.map((term, index) => (
              <p className="py-2">
                <span className="font-bold">{term.title}</span> {term.desc}
              </p>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsAndConditions
