import { useState, useEffect } from 'react'

import Popup from '../../../components/Popup'
import Modal from '../../../common/Modal'
import { Link, useNavigate } from 'react-router-dom'
import mctlogo from '../../../assets/images/mct_logo.svg'
import bgimg from '../../../assets/images/bgimg.png'
import { OUR_CARBON_TOKEN_CONTENT } from '../../../assets/content'

import new19 from '../../../assets/images/new19.jpg'
import MCT from '../../../assets/images/MCT2.png'

import Announcement from './Announcement'
import Timer from './Timer'

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import { useAccount } from 'wagmi'

const Web3 = require('web3')

function Banner({ isModalOpen, setModalOpen }) {
  const navigate = useNavigate()
  const { address } = useAccount()

  const [buyTokenPopup, setBuyTokenPopup] = useState(false)
  const [isCarbonTokenModal, setCarbonTokenModal] = useState(false)
  const [isPreSaleModal, setPreSaleModal] = useState(false)
  const [errText, setErrText] = useState('')
  const [successText, setSuccessText] = useState('')
  const [buyWithPopup, setBuyWithPopup] = useState(false)
  const [lastPopup, setLastPopup] = useState(false)
  const [tokenAmount, settokenAmount] = useState(1000)
  const [cryptoToPay, setCryptoToPay] = useState(0)
  const [totalPurchasedTokens, setTotalPurchasedTokens] = useState(0)

  const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545')

  const presaleContractData = require('./../../../PresaleContract.json')

  const presaleContractAbi = presaleContractData.abi
  const presaleContractAddress =
    process.env.REACT_APP_MCC_MCT_PRESALE_BLOCKCHAIN_ADDRESS

  const presaleContract = new web3.eth.Contract(
    presaleContractAbi,
    presaleContractAddress
  )

  const connectToMetamask = async () => {
    try {
      if (!window.ethereum) {
        setErrText('No crypto wallet found. Please install Metamask.')
        throw new Error('No crypto wallet found. Please install it.')
      }
      await window.ethereum.send('eth_requestAccounts').then((res) => {
        console.log(res)
        if (res.result !== []) {
          setBuyWithPopup(true)
          setSuccessText(`Connected to wallet id: ${res.result[0]}`)
          setBuyTokenPopup(false)
        }
      })
    } catch (err) {
      console.log(err)
      if (err.message === 'User rejected the request.')
        setErrText('Connection to Metamask Failed')
      else setErrText(err.message)
    }
  }

  const getPhasenPrice = async () => {
    const now = parseInt(new Date().getTime() / 1000, 10)
    const phase1startDate = Number(
      await presaleContract.methods.phase1Start().call()
    )
    const phase1endDate = Number(
      await presaleContract.methods.phase1End().call()
    )
    const phase2startDate = Number(
      await presaleContract.methods.phase2Start().call()
    )
    const phase2endDate = Number(
      await presaleContract.methods.phase2End().call()
    )
    const phase3startDate = Number(
      await presaleContract.methods.phase3Start().call()
    )
    const phase3endDate = Number(
      await presaleContract.methods.phase3End().call()
    )

    const phase1Price = await presaleContract.methods.phase1Price().call()
    const phase2Price = await presaleContract.methods.phase2Price().call()
    const phase3Price = await presaleContract.methods.phase3Price().call()
    // [previous_phase,current_phase,price,total_number_of_tokens]
    if (now < phase1startDate) {
      return [null, null]
    } else if (now >= phase1startDate && now <= phase1endDate)
      return [null, 1, Number(phase1Price), 100000000]
    else if (now > phase1endDate && now < phase2startDate) return [1, null]
    else if (now >= phase2startDate && now <= phase2endDate)
      return [null, 2, Number(phase2Price), 50000000]
    else if (now > phase2endDate && now < phase3startDate) return [2, null]
    else if (now >= phase3startDate && now <= phase3endDate)
      return [null, 3, Number(phase3Price), 50000000]
    else {
      return [null, null]
    }
  }
  const [currPhaseStatus, setcurrPhaseStatus] = useState([])
  // const startTransaction = async () => {
  //   setBuyWithPopup(false)
  //   setLastPopup(true)
  // }
  const exchange = async (noOfTokens, crypto) => {
    console.log('No of tokens to be purchased: ', noOfTokens)
    console.log('Amount in ETH to be spent: ', crypto)
    // Make sure to add validation of checking that no of tokens being purchased is <= 1000

    if (!window.ethereum) {
      setErrText('No crypto wallet found. Please install Metamask.')
      throw new Error('No crypto wallet found. Please install it.')
    }

    if (noOfTokens < 1000) {
      setErrText('Please buy a minimum of 1000 tokens')
      throw new Error('Please buy a minimum of 1000 tokens')
    } else {
      const noOfTokensWei = web3.utils.toWei(`${noOfTokens}`, 'ether')
      const noOfTokensFormatted = web3.utils.toBN(noOfTokensWei)

      const cryptoInWei = web3.utils.toWei(`${crypto}`)
      const cryptoInWeiFormatted = web3.utils.toBN(cryptoInWei)

      console.log('No of tokens: ', noOfTokensWei)
      console.log('Crypto to pay in wei: ', cryptoInWei)

      const buyTokens = await presaleContract.methods
        .buyTokens(noOfTokensFormatted)
        .send({
          from: address,
          value: cryptoInWeiFormatted,
        })

      const txnHash = buyTokens.transactionHash
      const txnStatus = buyTokens.status
      if (!txnStatus) {
        console.log(`Txn failed! Could not buy tokens, kindly try again`)
        alert(`Txn failed! Could not buy tokens, kindly try again`)
      } else {
        console.log(`Txn successful! Transaction hash: ${txnHash}`)
        alert(`Txn successful!`)
      }
    }

    // // set contact list to state variable.
    // setContactList(contactList);
    // // Then we get total number of contacts for iteration
    // const counter = await contactList.methods.count().call();
    // // iterate through the amount of time of counter
    // for (var i = 1; i <= counter; i++) {
    //   // call the contacts method to get that particular contact from smart contract
    //   const contact = await contactList.methods.contacts(i).call();
    //   // add recently fetched contact to state variable.
    //   setContacts((contacts) => [...contacts, contact]);
    // }

    // const provider = new ethers.providers.Web3Provider(ethereum);
    // const signer = provider.getSigner();
    // // // ethers.utils.getAddress(addr);
    // const tx = await signer.sendTransaction({
    //   to: "0x43d7013eFdaA4bab3Ec1057835D25AEC028B0003",
    //   value: ethers.parseEther(crypto),
    // });
    // console.log(crypto, "0x43d7013eFdaA4bab3Ec1057835D25AEC028B0003");
    // console.log("tx", tx);
    // setTransactionText([tx]);

    // // after transaction done
    // uploadToBackend();
  }

  const uploadToBackend = () => {
    //     axios.post('/user_login', {
    //       id:"id",
    // buyer_wallet_address:"buyer_wallet_address",
    // tokenprice:"tokenprice",
    // buyer_wallet_address:"buyer_wallet_address",
    // total_amount_paid:"total_amount_paid",
    // date+purchased:"date+purchased",
    // presale:"presale",
    //     })
    //     .then(function (response) {
    //       console.log(response);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
  }

  const roundFirstSignificantDigit = (input) => {
    if (input == 0) return input

    var precision = 0
    var val = input - Math.round(input, 0)
    while (Math.abs(val) < 1) {
      val *= 10
      precision++
    }
    return Math.round(val, precision) / 10 ** precision
  }

  useEffect(() => {
    async function setData() {
      // console.log('pre', roundFirstSignificantDigit(0.1))
      const phanseS = await getPhasenPrice()
      setcurrPhaseStatus(await getPhasenPrice())
      setTotalPurchasedTokens(
        await presaleContract.methods.totalPurchasedTokens().call()
      )
    }
    setData()
    // const getTotaltokens =  axios.get('/', {
    // })
    // .then(function (response) {
    // 	console.log(response);
    //   set tokens to a variable
    //   settokensBought(response)
    // })
    //   return () => {};
  }, [])

  const renderCarbonTokenModal = () => {
    return (
      <Modal
        isOpen={isCarbonTokenModal}
        headerTitle="What is MCT?"
        modalWidth="w-3/5 newfont_h2"
        primaryButtonText="Okay"
        handleClose={() => {
          setCarbonTokenModal(false)
        }}
        handlePrimaryClick={() => {
          setCarbonTokenModal(false)
        }}
        showPrimary={false}
        showSecondary={false}
      >
        <div className="flex flex-col lg:flex-row mt-2 items-center">
          <div className="ml-2">
            <img
              alt="mat_logo"
              className="w-[60px] sm:w-[100px] lg:w-[850px] xl:w-[700px]"
              src={mctlogo}
            />
          </div>
          <div className="p-4 max-h-[40vh] overflow-auto text-justify newfont_h2">
            {OUR_CARBON_TOKEN_CONTENT}
          </div>
        </div>
      </Modal>
    )
  }

  const renderPreSaleModal = () => {
    return (
      <Modal
        isOpen={isPreSaleModal}
        headerTitle="Presale"
        modalWidth="w-3/5 newfont_h2"
        primaryButtonText="Okay"
        handleClose={() => {
          setPreSaleModal(false)
        }}
        handlePrimaryClick={() => {
          setPreSaleModal(false)
        }}
        showPrimary={false}
        showSecondary={false}
      >
        <div className="p-4 max-h-[40vh] overflow-auto text-justify">
          <h1 className="text-xl text-black text-center font-semibold newfont_h1">
            Be a trailblazer in sustainability and invest in the MCT token
            today!!!{' '}
          </h1>
          <p className="my-4 p-2">
            Join the revolution towards a sustainable future with MacroCosmos
            Carbon Token (MCT). Our cutting-edge, blockchain-based platform is
            revolutionizing the way we offset carbon emissions. The MCT token is
            the key to our platform, facilitating carbon credit trading for your
            business in an efficient, transparent and secure way.
          </p>

          <p className="my-4 p-2">
            Not only will investing in MCT help you offset your own carbon
            footprint, but it also connects you with a community of like-minded
            individuals and socially responsible brands who are committed to
            reducing their impact on the environment. Each carbon offset
            transaction is verified and recorded via a tamper-proof digital
            certificate, ensuring accountability and traceability.
          </p>

          <p className="my-4 p-2">
            Take your sustainability efforts to the next level and invest in the
            MCT token today. Not only will you be doing your part in the fight
            against climate change, but you'll also be empowering the transition
            towards a greener & cleaner future for all.
          </p>
        </div>
      </Modal>
    )
  }
  return (
    <div
      className=" bg-cover bg-center mt-[-11rem]"
      style={{ backgroundImage: `url(${new19})` }}
    >
      <div className=" pt-96 pb-36 text-center px-4 mx-auto">
        <div className="relative flex flex-col md:flex-row justify-between align-center rounded-tl-[50px] max-w-[296px] sm:max-w-[324px] md:max-w-[641px] xl:max-w-[871px] rounded-br-[50px] p-[15px] bg-[#ffffff]/[0.9] mb-[50px] mx-auto ">
          <div>
            <div className="relative -top-4 flex flex-col justiy-center items-center ">
              <Announcement />
            </div>
            <div className="flex flex-col md:flex-row lg:flex-row items-center justify-between justify-center ">
              <div className="lg:w-[50%] flex flex-col md:flex-col  lg:flex-col items-center justify-center ">
                {/* <img src={MCT} alt="MCT"  style={{width:'100px', height:'100px'}}/> */}
                <div className=" ">
                  <h1 className="newfont_h1 lg:newfont_h1_big font-semibold airdrop-text text-[#626262] mb-2">
                    $MCT Airdrop is LIVE!
                  </h1>
                  {/* <Timer endDate="2024-01-04T05:30:00" /> */}
                </div>

                {/* <div className="flex flex-col items-center justify-center ">
              
              
            {!(process.env.REACT_APP_SHOW_SIGNUP === 'true') &&
              (currPhaseStatus && currPhaseStatus[1] !== null ? (
                currPhaseStatus[1] === 1 ? (
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                    MCT Presale Phase 1
                  </h1>
                ) : currPhaseStatus && currPhaseStatus[1] === 2 ? (
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                    MCT Presale Phase 2
                  </h1>
                ) : currPhaseStatus && currPhaseStatus[1] === 3 ? (
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                    MCT Presale Phase 3
                  </h1>
                ) : (
                  <></>
                )
              ) : currPhaseStatus && currPhaseStatus[0] === 1 ? (
                <>
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                    MCT Presale Phase I Ended
                  </h1>
                  <h4 className="text-center text-md text-black font-semibold mb-[10px] newfont_h1 ">
                    Phase - II coming soon
                  </h4>
                </>
              ) : currPhaseStatus && currPhaseStatus[0] === 2 ? (
                <>
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                    MCT Presale Phase II Ended
                  </h1>
                  <h4 className="text-center text-md text-black font-semibold mb-[10px] newfont_h1 ">
                    Phase - III coming soon
                  </h4>
                </>
              ) : currPhaseStatus && currPhaseStatus[0] === null ? (
                <>
                  <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px]">
                  MCT Presale Phase I
                </h1>
                <h4 className="text-center text-md text-black font-semibold mb-[10px]">
                  coming soon!
                </h4>

                <h1 className="text-center text-xl md:text-2xl text-[#034AA4] font-semibold mb-[10px]">
                  Sign up for MCT Presale! <br /> We'll update you with MCT
                  Presale Phase 1.
                </h1>
                </>
              ) : (
                <h1 className="text-center text-xl md:text-2xl text-black font-semibold mb-[10px] newfont_h1 ">
                  MCT Presale Phase III Ended
                </h1>
              ))}
            <h1 className="text-center text-sm md:text-md text-black font-normal mb-[10px]">
              1 CCHG = 0.013 USDT <br /> USDT Raised $302,847.17 / $2,080,000
            </h1>
            {currPhaseStatus &&
            currPhaseStatus[1] === null &&
            currPhaseStatus[0] === null ? (
            {process.env.REACT_APP_SHOW_SIGNUP === 'true' && (
              <>
                <Link
                  to="/presale-signup"
                  // onClick={() =>
                  //   window.open(
                  //     "https://docs.google.com/forms/d/e/1FAIpQLScBqh5O5JFuhWj47xgeQjHlMwNodhZzG5HaKiNXKQUBp3WwvQ/viewform",
                  //     "__blank"
                  //   )
                  // }
                  className={`newfont_h2 w-full md:w-[200px] lg:w-[60%] text-[#ffffff] font-semibold bg-[#008037] border-[#008037] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px]  mb-[10px] newfont_h1 `}
                >
                  Sign Up for Presale
                </Link>
                <Link
                  to="https://forms.gle/Mk9JwAWk1YChiVe96"
                  className="newfont_h2 w-full md:w-[205px] lg:w-full text-[#ffffff] font-semibold bg-blue-500  px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px]  mb-[16px] hover:bg-blue-700"
                  
                >
                  Register for Airdrop
                </Link>
                <h1 className="text-center text-sm md:text-md lg:text-lg text-[#034AA4] font-semibold newfont_h2">
                  Stay tuned for Presale launch!
                </h1>
              </>
            )}
            {!(process.env.REACT_APP_SHOW_SIGNUP === 'true') &&
              currPhaseStatus &&
              currPhaseStatus[0] === null && (
                <>
                  <div style={{ width: 150, height: 100 }}>
                    <CircularProgressbarWithChildren
                      value={parseInt(totalPurchasedTokens / 10 ** 18, 10)}
                      // value={30}
                      maxValue={currPhaseStatus[3]}
                      // maxValue={100}
                      strokeWidth={10}
                      styles={buildStyles({
                        rotation: 0.0,
                        strokeLinecap: 'butt',
                        pathTransitionDuration: 0.5,
                        textColor: '#004aad',
                        pathColor: '#008037',
                        trailColor: '#c7c7c7',
                      })}
                    >
                      <div className="text-xl  newfont_h3">
                        <strong className="">
                          {roundFirstSignificantDigit(
                            parseInt(totalPurchasedTokens / 10 ** 18, 10) /
                              currPhaseStatus[3]
                          )}
                          %
                        </strong>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <h1 className="mx-auto text-center text-sm md:text-md text-black  newfont_h3  mb-[10px] mt-16">
                    {parseInt(totalPurchasedTokens / 10 ** 18, 10)} tokens have
                    been sold till date
                  </h1>
                  <h1 className="mx-auto text-center text-sm md:text-md text-black  newfont_h3  mb-[10px]">
                    Buy before price increase!
                  </h1>
                </>
              )}
            <h1 className="text-center text-sm md:text-md text-black font-normal mb-[10px]">
              136,704,064 Tokens Remaining Until <br /> 1 CCHG = 0.0165 USDT
            </h1>
          </div> */}
                <button className="newfont_h2 w-full md:w-[200px] lg:w-[50%] text-[#ffffff] font-semibold bg-blue-500  px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px]  mb-[16px] hover:bg-blue-700">
                  <Link to="https://forms.gle/5ZUeFa9bbpnE1qDD8">
                    Register for Airdrop
                  </Link>
                </button>
              </div>
              <div className="lg:w-[50%] flex flex-col md:flex-col  lg:flex-col items-center justify-center mb-2 ">
                <div className="">
                  <h1 className=" airdrop-text newfont_h1 lg:newfont_h1_big font-semibold text-[#626262] mb-2">
                    $MCT Presale is LIVE!
                  </h1>
                  {/* <Timer endDate="2024-01-05T14:30:00" /> */}
                </div>

                {/* <button
              className={`w-full md:w-[200px] lg:w-[60%] text-[#ffffff] font-semibold bg-[#82a800]  px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]  newfont_h2 hover:bg-[#004aad]`}
              onClick={() => setCarbonTokenModal(true)}
            >
              Our Carbon Token
            </button>
            <button
              className={`w-[75%] md:w-[200px] lg:w-[50%] text-[#ffffff] font-semibold bg-[#008037] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]  newfont_h2  hover:bg-[#005625] `}
              
            >
            <Link to="/presale-signup">
            Sign Up for Presale
            </Link>
            </button> */}
                {/* {currPhaseStatus[1] !== null && (
            {currPhaseStatus[1] === null && currPhaseStatus[0] === null ? (
              <></>
            ) : ( */}
                <button
                  // onClick={() => setBuyTokenPopup(true)}
                  onClick={() => navigate('/buymct')}
                  className={`w-[80%] md:w-[200px] lg:w-[50%] text-[#ffffff] font-semibold bg-[#008037] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]  newfont_h2  hover:bg-[#005625]`}
                >
                  Buy MCT
                </button>
                {/* )} */}
              </div>
            </div>
            <div className="w-full px-[4px] flex justify-center items-center space-x-4">
              <Link
                to=""
                className="font-semibold mt-2 underline-offset-2 underline decoration-1 newfont_h3"
                onClick={() => setCarbonTokenModal(true)}
              >
                About MCT
              </Link>
              <Link
                to="/how-to-buy"
                className="font-semibold mt-2 underline-offset-2 underline decoration-1 newfont_h3"
              >
                How to Buy
              </Link>
              <div
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_MCC_MCT_DOC_WHITEPAPER,
                    '_blank'
                  )
                }}
                className="font-semibold mt-2 underline-offset-2 underline decoration-1 cursor-pointer newfont_h3"
              >
                White paper
              </div>
              {/* <Link
                  to="/faq"
                  className="font-semibold mt-2 underline-offset-2 underline decoration-1 newfont_h3"
                  >
                    FAQ
                </Link> */}
            </div>
            {buyTokenPopup && (
              <Popup>
                <button
                  onClick={() => connectToMetamask()}
                  className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#82A800] border-[#82A800] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]`}
                >
                  Metamask
                </button>
                <button
                  onClick={() => {
                    setBuyTokenPopup(false)
                    setErrText('')
                  }}
                  className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#131419] border-[#131419] px-[25px] py-[10px] border-[1px] rounded-tr-[15px] rounded-bl-[15px] mx-2 mb-[10px]`}
                >
                  Cancel
                </button>
                {errText && (
                  <p className="text-center text-sm md:text-md text-red-600 font-normal ">
                    {errText}
                  </p>
                )}
              </Popup>
            )}
            {/* {buyWithPopup && (
            <Popup>
              {successText && (
                <p className="text-center text-sm md:text-md text-green-600 font-normal mb-[10px]">
                  {successText}
                </p>
              )}
              <button
                onClick={() => startTransaction("ether")}
                className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#82A800] border-[#82A800] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]`}
              >
                Buy with ETH
              </button>
              <button
                onClick={() => startTransaction("tether")}
                className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#82A800] border-[#82A800] px-[25px] py-[10px] border-[1px] rounded-tr-[15px] rounded-bl-[15px] mx-2 mb-[10px]`}
              >
                Buy with USDT
              </button>
              <button
                onClick={() => {
                  setBuyWithPopup(false);
                }}
                className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#131419] border-[#131419] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]`}
              >
                Cancel
              </button>
            </Popup>
          )} */}
            {lastPopup && (
              <Popup>
                <h4 className="w-full text-left text-md text-[#000] font-semibold mb-2 ">
                  No. of Tokens
                </h4>
                <input
                  type="text"
                  onChange={async (e) => {
                    if (Number(e.target.value) >= 1000) {
                      settokenAmount(e.target.value)
                      setErrText('')
                    } else setErrText('Please buy a minimum of 1000 tokens')
                    // setCryptoToPay() // set crypto(ether) eq with tokens
                    // get token price in usd

                    const phaseStatus = await getPhasenPrice()
                    if (phaseStatus[0] !== null) {
                      try {
                        const phasePrice = await presaleContract.methods
                          .getPhasePrices(phaseStatus[0] - 1)
                          .call()
                        let cryptoToPay =
                          (Number(phasePrice) * Number(e.target.value)) /
                          10 ** 10
                        console.log('Crypto to pay: ', cryptoToPay)
                        setCryptoToPay(Number(cryptoToPay))
                        settokenAmount(e.target.value)
                        console.log(
                          'Amount in ETH that user has to pay for ',
                          e.target.value,
                          'MCT Tokens: ',
                          cryptoToPay
                        )
                        console.log(
                          'Current phase: per token price = ',
                          phaseStatus[1]
                        )
                      } catch (e) {
                        console.log(e, 'fetching crypto value failed')
                      }
                    } else {
                      // no phase is running now
                    }
                    // convert usd to ether
                    // ether to usd conversion
                  }}
                  placeholder="Please enter a value above 1000"
                  // defaultValue={1000}
                  className="text-left rounded mx-4 w-full px-4 py-2 mb-4 border-b-[1px] border-[#000] outline-none"
                />
                <h4 className="w-full text-left text-md text-[#000] font-semibold mb-2">
                  Value in Ether
                </h4>
                <input
                  type="text"
                  disabled
                  value={cryptoToPay}
                  placeholder="Value in ETH"
                  className="bg-[#fff] text-left rounded mx-4 w-full px-4 py-2 border-b-[1px] border-[#000] outline-none mb-2"
                />
                {/* <p className="text-center text-sm md:text-md text-black-600 font-normal my-[10px]">
                {cryptoToPay} Ether = {tokenValueInUSD} USD
              </p> */}
                <div className="flex justify-between">
                  <button
                    onClick={() => {
                      setLastPopup(false)
                    }}
                    className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#131419] border-[#82A800] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => exchange(tokenAmount, cryptoToPay)}
                    className={`w-[120px] md:w-[200px] text-[#ffffff] font-semibold bg-[#82A800] border-[#82A800] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px]`}
                  >
                    Confirm
                  </button>
                </div>
                {errText && (
                  <p className="text-center text-sm md:text-md text-red-600 font-normal ">
                    {errText}
                  </p>
                )}
              </Popup>
            )}
            {isCarbonTokenModal && renderCarbonTokenModal()}
            {isPreSaleModal && renderPreSaleModal()}
          </div>
        </div>
        {/* <div className='relative -top-12 flex flex-col justiy-center items-center '>
        <Announcement/> 
        </div> */}
      </div>
    </div>
  )
}
export default Banner
