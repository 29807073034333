import { Link, useLocation } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'

import { MdOutlineArrowDropDown } from 'react-icons/md'
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi'

import { toast } from 'react-toastify'
import { useState, useEffect } from 'react'

import logo from '../assets/images/logo.svg'
import '../screens/home/components/style.css'
import Announcement from '../screens/home/components/Announcement'

const handleSmallScreenNavbar = () => {
  document.getElementById('small-screens')?.classList.contains('hidden')
    ? document.getElementById('small-screens')?.classList.remove('hidden')
    : document.getElementById('small-screens')?.classList.add('hidden')
}

function Navbar() {
  const location = useLocation()
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()
  const { disconnect } = useDisconnect()

  const [showNavbar, setShowNavbar] = useState(true)

  useEffect(() => {
    let prevScrollPos = window.pageYOffset

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      const isScrollingDown = currentScrollPos > prevScrollPos

      setShowNavbar(!isScrollingDown)
      prevScrollPos = currentScrollPos
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav
      className={`bg-[#ffffff]/[1]  fixed px-2 z-10 bg-cover w-screen  border-t-1 border-b-[1px] border-gray-300`}
    >
      <div className="max-w-[1440px] mx-auto flex flex-row text-center px-4 w-screen justify-between items-center">
        <Link to="/" className="flex flex-row justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="h-[45px] w-[45px] md:h-[90px] md:w-[90px]"
          />
          <div>
            <h1 className="text-xl md:text-2xl text-[#000]  newfont_h1 font-bold">
              <span className="text-[#004aad]">Macro</span>
              <span className="text-[#00c2cb]">Cosmos</span>{' '}
              <span className="text-[#008037]">Creations</span> {'('}
              <span className="text-[#004aad]">M</span>
              <span className="text-[#00c2cb]">C</span>
              <span className="text-[#008037]">C</span>
              {')'}
            </h1>
            <h4 className="text-[#004aad]  newfont_h3  text-xs lg:text-sm">
              Sustainable Solutions, Powered by Science & Innovation
            </h4>
          </div>
        </Link>
        <ul className="hidden text-sm xl:text-lg lg:flex justify-center items-center ml-0  mr-0  ">
          <Link
            to="/"
            className="text-[#000] mx-4 cursor-pointer hover:text-[#004aad] navbar_newfont_h2   font-weight-[300]"
          >
            Home
          </Link>
          <li className="group relative inline-block text-[#000]  navbar_newfont_h2  mx-4 cursor-pointer hover:text-[#004aad]">
            <MdOutlineArrowDropDown className="inline" />
            <span>Sectors</span>
            <div className=" px-2 text-left min-w-max hidden absolute group-hover:block bg-[#ffffff]/[0.9] z-10 ">
              <Link
                to="/sector/climate-change-environmental-sustainability"
                className="p-2 block text-black hover:text-[#004aad] "
              >
                Environmental Sustainability
              </Link>
              <Link
                to="/sector/food-production-systems-food-security"
                className="p-2 block text-black hover:text-[#004aad]"
              >
                Food Systems
              </Link>
              <Link
                to="/sector/health-wellness"
                className="p-2 block text-black hover:text-[#004aad]"
              >
                Health and Wellness
              </Link>
            </div>
          </li>
          <div className="text-[#000] newfon mx-4 cursor-pointer hover:text-[#004aad]">
            <li className="group relative inline-block text-[#000] mx-4 cursor-pointer hover:text-[#004aad] navbar_newfont_h2 ">
              <MdOutlineArrowDropDown className="inline" />
              <span>Products</span>
              <div className="p-4 px-2 text-left min-w-max bg-opacity-80 hidden absolute group-hover:block bg-[#ffffff]/[0.9] z-10 ">
                <Link
                  to="/products/cosmos-e"
                  className="p-2 block text-black hover:text-[#004aad]"
                >
                  Cosmos-E
                </Link>
                <Link
                  to="/products/cosmos-f"
                  className="p-2 block text-black hover:text-[#004aad]"
                >
                  Cosmos-F
                </Link>
                <Link
                  to="/products/cosmos-h"
                  className="p-2 block text-black hover:text-[#004aad]"
                >
                  Cosmos-H
                </Link>
              </div>
            </li>
          </div>
          <div
            onClick={() => {
              window.open(
                process.env.REACT_APP_MCC_MCT_DOC_WHITEPAPER,
                '_blank'
              )
            }}
            className="text-[#000] navbar_newfont_h2 mx-4 cursor-pointer hover:text-[#004aad]"
          >
            White paper
          </div>
          <Link
            to="/contact"
            className="text-[#000]  navbar_newfont_h2  mx-4 cursor-pointer hover:text-[#004aad]"
          >
            Contact Us
          </Link>
          <Link
            to="/about"
            className="text-[#000] navbar_newfont_h2  mx-4 cursor-pointe hover:text-[#004aad]"
          >
            About Us
          </Link>
          {location.pathname === '/buymct' &&
            (!isConnected ? (
              connectors.map((connector) => {
                return (
                  <button
                    disabled={!connector.ready}
                    className={`border rounded-tl-[15px] rounded-br-[15px] ${
                      connector.ready ? 'bg-[#004aad]' : 'bg-gray-500'
                    } text-white mx-4 py-1 px-2 cursor-pointer transition-colors duration-200 hover:bg-[#008037]`}
                    onClick={() => {
                      if (connector.ready) {
                        connect({ connector })
                      }
                    }}
                  >
                    {connector.ready && !isLoading && (
                      <span className="navbar_newfont_h2 ">
                        Connect to {connector.name}
                      </span>
                    )}
                    {!connector.ready && <span>Unsupported</span>}
                    {isLoading && connector.id === pendingConnector?.id && (
                      <span>Connecting</span>
                    )}
                    {error && toast.error(error.message)}
                  </button>
                )
              })
            ) : (
              <div className="flex justify-end align-center">
                <button
                  onClick={disconnect}
                  className={`border rounded-tl-[15px] bg-red-500 rounded-br-[15px] navbar_newfont_h2  text-white mx-4 py-1 px-2 cursor-pointer transition-colors duration-200 hover:bg-red-600`}
                >
                  Disconnect Metamask
                </button>
              </div>
            ))}
        </ul>
        <GiHamburgerMenu
          className="lg:hidden text-4xl text-[#008037] "
          onClick={handleSmallScreenNavbar}
        />
      </div>
      <div
        className="hidden text-sm absolute right-0 bg-[#ffffff]/[0.9] mb-4"
        id="small-screens"
      >
        <ul className="flex flex-col text-start p-4">
          {location.pathname === '/buymct' &&
            (!isConnected ? (
              connectors.map((connector) => {
                return (
                  <button
                    disabled={!connector.ready}
                    className={`border rounded-tl-[15px] rounded-br-[15px] ${
                      connector.ready ? 'bg-[#008037]' : 'bg-gray-500'
                    } text-white mx-4 py-1 px-2 cursor-pointer transition-colors duration-200 hover:bg-green-600`}
                    onClick={() => {
                      if (connector.ready) {
                        connect({ connector })
                      }
                    }}
                  >
                    {connector.ready && !isLoading && (
                      <span>Connect to {connector.name}</span>
                    )}
                    {!connector.ready && <span>Unsupported</span>}
                    {isLoading && connector.id === pendingConnector?.id && (
                      <span>Connecting</span>
                    )}
                    {error && toast.error(error.message)}
                  </button>
                )
              })
            ) : (
              <div className="flex justify-center align-center">
                <button
                  onClick={disconnect}
                  className={`border rounded-tl-[15px] bg-red-500 rounded-br-[15px] text-white mx-4 py-1 px-2 cursor-pointer transition-colors duration-200 hover:bg-red-600`}
                >
                  Disconnect
                </button>
              </div>
            ))}
          <Link
            to="/"
            className="hover:text-[#004aad] py-4  navbar_newfont_h2  "
          >
            Home
          </Link>
          <li className="text-[#000]  navbar_newfont_h2  cursor-pointer py-4">
            <span className="hover:text-[#004aad]">Sectors</span>
            {/* Can use state var to hide unhide on click, please remove document. logic and add state, I was tring to just make it work, no necessary but we can remove it now or future iterations */}
            <div
              className="p-4 min-w-max bg-opacity-80 z-50 flex flex-col"
              id="sector-list"
            >
              <Link
                to="/sector/climate-change-environmental-sustainability"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Environmental Sustainability
              </Link>
              <Link
                to="/sector/food-production-systems-food-security"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Food Systems
              </Link>
              <Link
                to="/sector/health-wellness"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Health and Wellness
              </Link>
            </div>
          </li>
          {/* Same thing for products */}
          <li className="text-[#000] navbar_newfont_h2  cursor-pointer">
            <span className="hover:text-[#004aad]">Products</span>
            <div
              className="p-4 min-w-max bg-opacity-80 flex flex-col z-50"
              id="product-list"
            >
              <Link
                to="/products/cosmos-e"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Cosmos-E
              </Link>
              <Link
                to="/products/cosmos-f"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Cosmos-F
              </Link>
              <Link
                to="/products/cosmos-h"
                className="p-2 block hover:text-[#004aad]"
                onClick={() => {
                  document
                    .getElementById('small-screens')
                    ?.classList.add('hidden')
                }}
              >
                Cosmos-H
              </Link>
            </div>
          </li>
          <div
            onClick={() => {
              window.open(
                process.env.REACT_APP_MCC_MCT_DOC_WHITEPAPER,
                '_blank'
              )
            }}
            className="text-[#000]  navbar_newfont_h2  cursor-pointer hover:text-[#004aad] py-4"
          >
            Whitepaper
          </div>
          <Link
            to="/contact"
            className="text-[#000]  navbar_newfont_h2  cursor-pointer hover:text-[#004aad] py-4"
          >
            Contact Us
          </Link>
          <Link
            to="/about"
            className="text-[#000] navbar_newfont_h2  cursor-pointe hover:text-[#004aad] py-4"
          >
            About Us
          </Link>
        </ul>
      </div>
      {/* <div>
        <Announcement/>
      </div> */}

    </nav>
  )
}

export default Navbar
