import SocialMedia from '../../common/SocialMedia'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import '../home/components/style.css'

function AboutPage() {
  return (
    <>
      <Navbar />
      <div className="text-justify pt-28 px-4 mx-auto mb-[2%] lg:mb-[4%] max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] newfont_h2">
        <p className="">
          At MacroCosmos Creations Private Limited (MCC), we are a team of
          researchers and engineers from around the world, united by our
          commitment to creating sustainable solutions for a better future. Our
          founders,{' '}
          <span
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/abhishek-mani-tripathi-ph-d-bb441617/',
                '__blank'
              )
            }
            className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer"
          >
            Dr. Abhishek Mani Tripathi
          </span>{' '}
          and{' '}
          <span
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/ketaki-vasant-phadke-phd-67b0a4155',
                '__blank'
              )
            }
            className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer"
          >
            Dr. Ketaki Vasant Phadke
          </span>
          , are both PhD holders with strong backgrounds in research and
          development.
        </p>
        <br />
        <p className="">
          Dr. Tripathi holds a PhD in Forest Ecology and Landscape Engineering
          from Mendel University in Brno, Czech Republic, Europe. He has worked
          on projects related to carbon management, precision agriculture, and
          sustainable food and agri solutions. Dr. Phadke holds a PhD in
          Biophysics (voice science) and brings her expertise in voice analysis
          and scientific research to the team.
        </p>
        <br />
        <p className="">
          MCC envisions a future where people can easily access high-quality
          food, and where there's enough food production to supply a population
          of 10 billion by 2050, all while achieving the net-zero goal and
          promoting health and wellness. To achieve this, we rely on sustainable
          solutions supported by scientific research and innovation. Our mission
          is to tackle the pressing issues related to{' '}
          <span
            onClick={() =>
              window.open(
                'https://www.researchgate.net/profile/Abhishek_Tripathi6',
                '__blank'
              )
            }
            className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer"
          >
            environmental sustainability, food systems
          </span>{' '}
          and{' '}
          <span
            onClick={() =>
              window.open(
                'https://scholar.google.com/citations?user=QyV1oLYAAAAJ&hl=en',
                '__blank'
              )
            }
            className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer"
          >
            health & wellness
          </span>{' '}
          by developing solutions that make a positive impact on the world.
        </p>
        <br />
        <p className="">
          We are committed to our values of ethical business practices,
          transparency, diversity and inclusion. We strive to ensure that our
          customers and partners understand the purpose and benefits of each of
          our products, designed to support a better future for all. We believe
          that our collective efforts can make a positive impact on the world.
        </p>
      </div>
      <SocialMedia />
      <Footer />
    </>
  )
}

export default AboutPage
