import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import Privacy from './components/Privacy'

function PrivacyIndex() {
  return (
    <div className="relative overflow-x-hidden text-center w-screen flex flex-col">
      <Navbar />
      <Privacy />
      <Footer />
    </div>
  )
}

export default PrivacyIndex
