import { useRouteError } from 'react-router-dom'

import SocialMedia from '../../common/SocialMedia'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'

function ErrorPage() {
  const error = useRouteError()
  return (
    <>
      <Navbar />
      <div className="my-[2%] lg:my-[4%] text-center px-4 mx-auto min-h-[50vh]">
        <span>
          Oops, Something unexpected happened. Please Navigate elsewhere
        </span>
        <p>
          <i>{`Error: ${error.statusText || error.message}`}</i>
        </p>
      </div>
      <SocialMedia />
      <Footer />
    </>
  )
}

export default ErrorPage
