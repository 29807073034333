import { useMutation } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SocialMedia from '../../common/SocialMedia'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'

import { submitForm } from './contactUtils'
import { useState } from 'react'

import '../home/components/style.css'

function ContactPage() {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })
  const contactMutation = useMutation(submitForm, {
    onSuccess: () => {
      setFormState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      })
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    contactMutation.mutate(formState)
  }

  return (
    <>
      <Navbar />
      <div className=''>
      <form
        id="contactForm"
        className="my-8 mx-4 lg:my-16 pt-[90px] lg:mx-auto max-w-[1440px] "
        onSubmit={handleSubmit}
      >
        <section className="text-gray-700 body-font relative newfont_h2">
          <div className="container px-5 pb-[2%] lg:pb-[4%] mx-auto">
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="firstName"
                      className="leading-7 text-sm text-gray-600"
                    >
                      First Name *
                    </label>
                    <input
                      type="text"
                      required={true}
                      id="firstName"
                      value={formState.firstName}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return {
                            ...formState,
                            firstName: e.target.value,
                          }
                        })
                      }
                      name="name"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="lastName"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Last Name *
                    </label>
                    <input
                      type="text"
                      required={true}
                      id="lastName"
                      value={formState.lastName}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return {
                            ...formState,
                            lastName: e.target.value,
                          }
                        })
                      }
                      name="name"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Email *
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={formState.email}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return {
                            ...formState,
                            email: e.target.value,
                          }
                        })
                      }
                      required={true}
                      name="email"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Message *
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      required={true}
                      value={formState.message}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return {
                            ...formState,
                            message: e.target.value,
                          }
                        })
                      }
                      maxLength={200}
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full " type="submit">
                  <button
                    className=" w-[50%] md:w-[45%] lg:w-[40%] text-[#ffffff] font-semibold bg-[#008037] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px] newfont_h2 hover:bg-[#006329]"
                    disabled={contactMutation.isLoading}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      </div>
      <ToastContainer />
      <SocialMedia />
      <Footer />
    </>
  )
}

export default ContactPage
