import React, { useState, useEffect, useMemo } from "react";
import ad1 from "../../../assets/images/banner2.png";
import ad2 from "../../../assets/images/banner1.png";

const Ad = () => {
  const images = useMemo(() => [ad1,ad2], []);
  const [index, setIndex] = useState(0);

  const adLinks = [
    'https://forms.gle/5ZUeFa9bbpnE1qDD8', 
    '/buymct', 

  ];

  const handleImageClick = () => {
    const selectedAdLink = adLinks[index];
    if (selectedAdLink) {
      window.location.href = selectedAdLink;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className='flex justify-center bg-[#fafafa] pt-4 pb-4'>
      <div className='max-w-full sm:max-w-full lg:max-w-[978px]'>
        <img
          className='cursor-pointer'
          src={images[index]}
          alt={`Banner ${index + 1}`}
          style={{ maxWidth: '100%', borderRadius: '20px' }}
          onClick={handleImageClick}
        />
        <div className='flex justify-center mt-2 cursor-pointer'>
          {images.map((image, i) => (
            <button
              key={i}
              onClick={() => setIndex(i)}
              className={`w-2 h-2 mx-1 rounded-full ${
                index === i ? 'bg-[#008037]' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ad;
