import { useEffect } from 'react'
import { MdClose } from 'react-icons/md'

const ModalHeader = ({ headerTitle, handleClose, showCloseIcon }) => {
  return (
    <div className="flex items-center justify-between p-2 bg-[#008037] rounded-t-md">
     <span className="text-white text-center w-full">{headerTitle}</span>
      {/* <span className="text-white ">{headerTitle}</span> */}
      {showCloseIcon && (
        <MdClose
          className="text-white text-2xl cursor-pointer"
          onClick={handleClose}
        />)
      }
    </div>
  )
}

const ModalFooter = ({ isPrimary, isSecondary }) => {
  return (
    <div className="flex items-center justify-end gap-4 p-2 rounded-x rounded-t border-t border-x border-b border-x-[#82A800] border-b-[#82A800] border-t-gray-200">
      {isPrimary.showPrimary && isPrimary.render()}
      {isSecondary.showSecondary && isSecondary.render()}
    </div>
  )
}

const Modal = ({
  isOpen = false,
  modalWidth,
  headerTitle,
  showCloseIcon = true,
  handleClose = () => {},
  handlePrimaryClick = () => {},
  primaryButtonText = '',
  showPrimary = true,
  handleSecondaryClick = () => {},
  secondaryButtonText = '',
  showSecondary = true,
  children,
}) => {
  const handlePrimaryFunction = {
    showPrimary: showPrimary,
    render: () => {
      return (
        <div>
          <button
            onClick={(e) => {
              e.stopPropagation()
              handlePrimaryClick()
            }}
            className="text-white bg-[#82A800] lg:rounded-lg md:rounded-md p-1"
          >
            {primaryButtonText}
          </button>
        </div>
      )
    },
  }

  const handleSecondaryFunction = {
    showSecondary: showSecondary,
    render: () => {
      return (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation()
              handleSecondaryClick()
            }}
          >
            {secondaryButtonText}
          </button>
        </>
      )
    },
  }

  useEffect(() => {
    if (isOpen)
      document.getElementById('body')?.classList.add('overflow-hidden')
    return () => {
      document.getElementById('body')?.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 rounded-md backdrop-blur-sm flex justify-center items-center z-20 ">
        <div
          className={`${
            modalWidth ? modalWidth : 'w-4/5'
          }  bg-white rounded-md shadow-lg shadow-gray-500`}
        >
          <ModalHeader headerTitle={headerTitle} handleClose={handleClose} showCloseIcon={showCloseIcon} />
          {children}
          {(showPrimary || showSecondary) && (
            <ModalFooter
              isPrimary={handlePrimaryFunction}
              isSecondary={handleSecondaryFunction}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Modal
