import React from 'react'
import Marquee from 'react-fast-marquee'
import "./style.css"
import { IoMdNotifications } from 'react-icons/io';


const Announcement = () => {
    const handleImageClick = () => {

        window.location.href = 'https://forms.gle/Mk9JwAWk1YChiVe96';  
      };
      const handleClick = () => {

        window.location.href = '/buymct';  
      };
  return (
   
    <div className='announcement  max-w-[280px] sm:max-w-[324px] md:max-w-[641px] xl:max-w-[867px]'>
        <IoMdNotifications
          className='mr-2 text-[#FF8400]'
          style={{ fontSize: '28px', transform: 'rotate(-20deg)' }} // Inline styles
        />
        {/* <img src={nf} alt='image'style={{width:'60px', height:'40px'}}/> */}
    <Marquee speed={60} pauseOnHover>
    <div className='announcement-text text1 cursor-pointer font-semibold'>
    <div>

    <span onClick={handleImageClick}>Don't Miss Out! Participate in the Airdrop🎁 and Grab Your Rewards!🚀🚀🚀</span>
    <span onClick={handleClick} className='text-blue-600'>
    🌿Join the Green Revolution. Buy MCT, be a Pioneer in Fighting Climate Change🌎🌱
        </span>

    </div>
    <div className='announcement-text text2 cursor-pointer'>
        <span onClick={handleImageClick}>Don't Miss Out! Participate in the Airdrop🎁 and Grab Your Rewards!🚀🚀🚀</span>
        <span onClick={handleClick} className='text-blue-600'>
        🌿Join the Green Revolution. Buy MCT, be a Pioneer in Fighting Climate Change🌎🌱
        </span>
    </div>
    </div>
    </Marquee>
    </div>
  )
}

export default Announcement