import './style.css';
import Climate from "../../../assets/images/blogs/climate.jpg"
import Food from "../../../assets/images/blogs/food.jpg"
import Health from "../../../assets/images/blogs/health.jpg"

function CustomBlogCard({ imageUrl, blogURL, heading, description }) {
  return (
    <div
      className="w-full sm:w-1/2 lg:w-1/3 text-center my-4  lg:mx-4 cursor-pointer  bg-cover border-[#008037]  blog-card blog-card:hover"
      onClick={() => window.open(blogURL, '_blank')}
    >
      <div
        className="h-[200px] w-[230px] lg:w-[280px] mx-auto bg-cover border-[#008037] border-b-[3px] blog-card blog-card:hover"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <h4 className="font-semibold mt-[10px] mb-2 newfont_h2">
        {heading}
      </h4>
      <p className="text-md text-[#888888] newfont_h2 mb-[14px] px-4 ">
        {description}
      </p>
    </div>
  )
}

function Blogs() {
  return (
    <div className='ml-2 sm:ml-2 mr-2 sm:mr-2'>
    <div className="py-20 text-center px-4 max-w-full sm:max-w-full lg:max-w-[978px] mx-auto card-container">
      <h1 className="text-xl md:text-3xl font-semibold mb-[20px] newfont_h1">Blogs</h1>
      <p className="text-md newfont_h2 mb-[14px]">
        Welcome to MCC's blog section, where we share expert insights on our
        products and services. Our articles cover topics related to products and
        or services area, such as tips and tricks, industry trends, and best
        practices. You will find our blogs informative and useful to get the
        most out of our products and services.
      </p>
      <div className="flex flex-col md:flex-row justify-center content-center   md:justify-between items-center newfont_h2">
     
      <CustomBlogCard
          // imageUrl="https://miro.medium.com/fit/c/224/224/0*mo8GYh9LsdKxoD2x"
          imageUrl={Climate}          
          blogURL="https://medium.com/@creationsmacrocosmos/building-a-decentralized-future-the-power-of-blockchain-and-tokenization-in-carbon-management-77ebcab1f044"
          heading="The Power of Blockchain and Tokenization in Carbon Management"
          description="Climate change is one of the biggest challenges of our time…"
        />
        <CustomBlogCard 
          // imageUrl="https://miro.medium.com/fit/c/224/224/1*tT-yBQocBTUus8NIMga-sw.png"
          imageUrl={Food}
          blogURL="https://medium.com/@creationsmacrocosmos/macrocosmos-creations-revolutionizing-food-production-with-advance-technology-and-sustainable-ad6cd81887e7"
          heading="Revolutionizing Food Production with Advance Technology and Sustainable Agriculture"
          description="Food production systems and food security have become.."
        />

        <CustomBlogCard
          // imageUrl="https://miro.medium.com/fit/c/224/224/0*uOV8SiEM9vcOMrSk"
          imageUrl={Health}
          blogURL="https://medium.com/@creationsmacrocosmos/the-human-voice-voice-disorders-and-voice-therapy-7162d0740a10"
          heading="The Human Voice, Voice Disorders and Voice Therapy"
          description="The Human Voice, Voice Disorders and Voice Therapy Voice, which…"
        />
      </div>
    </div>
    </div>
  )
}

export default Blogs
