import { toast } from 'react-toastify'

export const createBuyerOnBlockchinaTransactionSuccess = async (payload) => {
  const data = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/buyer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
  })
  if (data.ok) {
    toast.success(
      <div className="text-white">Your transaction is successfull</div>
    )
  }
  return data.json()
}
