import { useState } from 'react'
import { MdExpandMore, MdExpandLess } from 'react-icons/md'

const Accordian = ({ title, content }) => {
  const [isExpanded, setExpanded] = useState(false)
  const handleExpandAndCollapse = () => {
    setExpanded((prevValue) => !prevValue)
  }
  return (
    <>
      <div className="max-w-[80%] mx-auto my-2">
        <div className="rounded-md border-2">
          <div
            className="py-4 px-6 cursor-pointer flex items-center justify-between"
            onClick={handleExpandAndCollapse}
          >
            <span className="font-semibold">{title}</span>
            {!isExpanded ? (
              <MdExpandMore className="text-2xl" />
            ) : (
              <MdExpandLess className="text-2xl" />
            )}
          </div>
          <div
            className={`${
              isExpanded ? 'max-h-max' : 'max-h-0 hidden'
            } + "transition-all duration-200 ease-out"`}
          >
            <div className="p-4 border-t text-justify">
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordian
