import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../screens/home/components/style.css'

import { submitForm } from './EnquiryFormUtil'

const EnquiryForm = () => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    enquiryType: '',
    message: '',
  })

  const enquiryMutation = useMutation(submitForm, {
    onSuccess: () => {
      setFormState({
        firstName: '',
        lastName: '',
        email: '',
        enquiryType: '',
        message: '',
      })
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    enquiryMutation.mutate(formState)
  }

  return (
    <form
      id="enquiryForm"
      className="lg:w-[50%] px-2 lg:px-4 newfon"
      onSubmit={handleSubmit}
    >
      <h2 className="text-lg lg:ml-4 lg:mr-2 lg:text-xl text-[#000000]/[0.9] text-center font-semibold newfont_h1">
        ENQUIRY FORM
      </h2>
      <section className="mt-4 lg:mt-6 card-container">
        <fieldset className="lg:flex justify-between items-center">
          <input
            type="text"
            name="firstName"
            value={formState.firstName}
            onChange={(e) =>
              setFormState((formState) => {
                return { ...formState, firstName: e.target.value }
              })
            }
            required={true}
            className="rounded lg:ml-4 lg:mr-2 w-full lg:w-[50%] px-4 py-2 mb-4 newfont_h2 w-full border-[2px] border-[#000000]/[0.6] focus:border-[#008037] text-base outline-none text-gray-700  transition-colors duration-200 ease-in-out"
            placeholder="First name*"
          />
          <input
            type="text"
            name="lastName"
            value={formState.lastName}
            onChange={(e) =>
              setFormState((formState) => {
                return { ...formState, lastName: e.target.value }
              })
            }
            required={true}
            className="rounded lg:mr-4 lg:ml-2 w-full lg:w-[50%] px-4 py-2 mb-4 newfont_h2 w-full border-[2px] border-[#000000]/[0.6] focus:border-[#008037] text-base outline-none text-gray-700  transition-colors duration-200 ease-in-out"
            placeholder="Last Name*"
          />
        </fieldset>
        <fieldset className="lg:flex justify-between items-center">
          <input
            type="email"
            name="email"
            value={formState.email}
            onChange={(e) =>
              setFormState((formState) => {
                return { ...formState, email: e.target.value }
              })
            }
            required={true}
            className="rounded lg:mx-4 w-full px-4 py-2 mb-4 md:mb-3 newfont_h2
            w-full border-[2px] border-[#000000]/[0.6] focus:border-[#008037] text-base outline-none text-gray-700  transition-colors duration-200 ease-in-out"
            placeholder="Enter your email here*"
          />
        </fieldset>
        <fieldset className="lg:flex justify-between items-center">
          <select
            className="rounded lg:mx-4 w-full px-4 py-2 mb-4 md:mb-3 newfont_h2
            w-full border-[2px] border-[#000000]/[0.6] focus:border-[#008037] text-base outline-none text-gray-700  transition-colors duration-200 ease-in-out"
            name="enquiryType"
            id="enquiryOption"
            required={true}
            value={formState.enquiryType}
            onChange={(e) =>
              setFormState((formState) => {
                return { ...formState, enquiryType: e.target.value }
              })
            }
            placeholder="Enquiry Type"
          >
            <option value="">Select an option</option>
            <option value="Environmental Sustainability">
              Environmental Sustainability
            </option>
            <option value="Food Systems">Food Systems</option>
            <option value="Health and Wellness">Health And Wellness</option>
          </select>
        </fieldset>
        <fieldset className="lg:flex justify-between items-center">
          <textarea
            className="rounded lg:mx-4 w-full px-4 py-2 mb-4 md:mb-0 newfont_h2 
            w-full rounded border-[2px] border-[#000000]/[0.6] focus:border-[#008037] text-base outline-none text-gray-700  transition-colors duration-200 ease-in-out"
            // border border-[#ffffff] focus:border-[#82A800] transition-colors duration-200 ease-in-out
            name="message"
            id="message"
            cols="20"
            required={true}
            rows="5"
            value={formState.message}
            onChange={(e) =>
              setFormState((formState) => {
                return { ...formState, message: e.target.value }
              })
            }
            placeholder="Message*"
            maxLength={200}
          />
        </fieldset>
        <fieldset className="lg:flex justify-between items-center">
          <button
            type="submit"
            className="lg:mx-4 w-full px-4 py-2 mb-4 md:mb-0 mt-2 bg-[#008037] text-white newfont_h2 hover:bg-[#006329] rounded-tl-[15px] rounded-br-[15px]"
          >
            Submit Enquiry
          </button>
        </fieldset>
      </section>
      <ToastContainer />
    </form>
  )
}

export default EnquiryForm
