import { useState, useEffect, useCallback } from 'react'
import './style.css'
import {
  useAccount,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
  useDisconnect,
} from 'wagmi'
import Navbar from '../../../common/Navbar'
import Footer from '../../../common/Footer'
import Modal from '../../../common/Modal'
import { ToastContainer, toast } from 'react-toastify'
import { createBuyerOnBlockchinaTransactionSuccess } from './Utils/TokenInfoUtils'
import { useMutation } from '@tanstack/react-query'
import { presaleContract, web3 } from '../../../web3config'

const targetChainId = Number(process.env.REACT_APP_MCC_CHAIN_ID)
const blockchainNetwork = process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK // goerli, mainnet, etc
let explorerLink

if (blockchainNetwork == 'mainnet') {
  explorerLink = `https://etherscan.io/tx/`
} else {
  explorerLink = `https://${blockchainNetwork}.etherscan.io/tx/`
}

const TokenInfo = () => {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain, chains } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()
  const { data: ensName } = useEnsName({ address })
  const [userAggrementAccepted, setUserAggrementAccepted] = useState(false)
  const [priceIntervalId, setPriceIntervalId] = useState([])
  const [tokenAmount, settokenAmount] = useState(null)
  const [phasePrice, setPhasePrice] = useState(0)
  const [currPhaseStatus, setcurrPhaseStatus] = useState([])
  const [showTokenBought, setShowTokenBought] = useState(false)
  const [transactionProgress, setTransactionProgress] = useState(false)
  const [transactionStatus, setTransactionStatus] = useState(null)
  const [txnHash, setTxnHash] = useState(null)
  const [totalPurchasedTokens, setTotalPurchasedTokens] = useState(0)
  const [userDetails, setUserDetails] = useState(null)
  const [cryptoToPay, setCryptoToPay] = useState(null)
  const [errText, setErrText] = useState('')

  const currentTransationMutation = useMutation(
    createBuyerOnBlockchinaTransactionSuccess
  )

  useEffect(() => {
    return () => disconnect()
  }, [])

  useEffect(() => {
    priceIntervalId.forEach((item) => clearInterval(item))
    setCryptoToPay(null)
  }, [tokenAmount])

  const getPhasenPrice = useCallback(async () => {
    const now = parseInt(new Date().getTime() / 1000, 10)
    const phase1startDate = await presaleContract.methods.phase1Start().call()
    const phase1endDate = await presaleContract.methods.phase1End().call()
    const phase2startDate = await presaleContract.methods.phase2Start().call()
    const phase2endDate = await presaleContract.methods.phase2End().call()
    const phase3startDate = await presaleContract.methods.phase3Start().call()
    const phase3endDate = await presaleContract.methods.phase3End().call()

    const phase1Price = await presaleContract.methods.phase1Price().call()
    const phase2Price = await presaleContract.methods.phase2Price().call()
    const phase3Price = await presaleContract.methods.phase3Price().call()

    setTotalPurchasedTokens(
      await presaleContract.methods.totalPurchasedTokens().call()
    )

    if (now < phase1startDate) return [null, null]
    else if (now >= phase1startDate && now <= phase1endDate)
      return [1, phase1Price, 100000000]
    else if (now > phase1endDate && now < phase2startDate) return [1, null]
    else if (now >= phase2startDate && now <= phase2endDate)
      return [2, phase2Price, 50000000]
    else if (now > phase2endDate && now < phase3startDate) return [2, null]
    else if (now >= phase3startDate && now <= phase3endDate)
      return [3, phase3Price, 50000000]
  }, [presaleContract])

  useEffect(() => {
    if (chain && chain.id !== targetChainId) {
      switchNetwork?.(targetChainId)
    }
    if (isConnected && window.ethereum && chain && chain.id === targetChainId) {
      async function getUserDetails() {
        setUserDetails(
          await presaleContract.methods.userDetails(address).call()
        )
      }
      if (chain && chain.id === targetChainId) {
        getUserDetails()
      } else {
        setUserDetails(null)
      }
    }
  }, [chain, address, isConnected, switchNetwork])

  useEffect(() => {
    async function setData() {
      setcurrPhaseStatus(await getPhasenPrice())
    }
    if (chain && chain.id === targetChainId) setData()
  }, [chain])

  useEffect(() => {
    if (chain && isConnected && chain.id === targetChainId) {
      async function getUserDetails() {
        setUserDetails(
          await presaleContract.methods.userDetails(address).call()
        )
      }
      getUserDetails()
    } else {
      setUserDetails(null)
    }
  }, [isConnected, chain, address])

  const memoisedGetAmountToPay = useCallback(
    async function getAmountToPay() {
      priceIntervalId.forEach((item) => clearInterval(item))
      const phaseStatus = await getPhasenPrice()
      if (tokenAmount < 1000) {
        setPhasePrice(0)
        setCryptoToPay(null)
      }

      // Hardcoded logic for phase 1

      const totalPurchasedTokens = await presaleContract.methods
        .totalPurchasedTokens()
        .call() // only phase 1

      if (
        phaseStatus[0] !== null &&
        tokenAmount > phaseStatus[2] - Number(totalPurchasedTokens) / 10 ** 18
      ) {
        toast.error(
          <div className="text-white">
            The value entered exceeds the maximum available tokens in this phase
          </div>
        )
      }

      if (
        phaseStatus[0] !== null &&
        tokenAmount >= 1000 &&
        tokenAmount <= phaseStatus[2] - Number(totalPurchasedTokens) / 10 ** 18
      ) {
        try {
          async function getPrice() {
            const phasePrice = await presaleContract.methods
              .getPhasePrices(phaseStatus[0] - 1)
              .call()
            let cryptoToPay =
              (Number(phasePrice) * Number(tokenAmount)) / 10 ** 10
            setPhasePrice(phasePrice)
            setCryptoToPay(Number(cryptoToPay))
          }
          let intervalId = setInterval(getPrice, 5000)
          setPriceIntervalId((prevPriceIntervalId) => [
            ...prevPriceIntervalId,
            intervalId,
          ])
        } catch (e) {
          toast.error(
            <div className="text-white">
              Encountered an error:{' '}
              <p style={{ color: 'black' }}>
                Fetching value from blockchain failed.
              </p>{' '}
              Please try again
            </div>
          )
        }
      } else if (phaseStatus[0] == null) {
        toast.error(
          <div className="text-white">
            All presale phases are currently closed. Please check back later!
          </div>
        )
      }
      // convert usd to ether
      // ether to usd conversion
    },
    [
      getPhasenPrice,
      tokenAmount,
      presaleContract,
      setPhasePrice,
      setCryptoToPay,
      priceIntervalId,
      setPriceIntervalId,
    ]
  )

  useEffect(() => {
    if (chain && chain.id === targetChainId) {
      memoisedGetAmountToPay()
    } else {
      priceIntervalId.forEach((item) => clearInterval(item))
    }
  }, [chain, tokenAmount])

  useEffect(() => {
    if (chain && chain.id !== targetChainId) {
      setPhasePrice(0)
      setCryptoToPay(null)
      settokenAmount(null)
    }
  }, [chain])

  const handleNumberOfTokens = (e) => {
    console.log(e.target.value)
    if (Number(e.target.value) >= 1000) {
      setErrText('')
    } else {
      setErrText('Please buy a minimum of 1000 tokens')
    }
    settokenAmount(parseInt(e.target.valueAsNumber) || null)
  }

  const exchange = async (noOfTokens, crypto) => {
    setTransactionProgress(true)
    try {
      if (noOfTokens < 1000) {
        setErrText('Please buy a minimum of 1000 tokens')
        throw new Error('Please buy a minimum of 1000 tokens')
      } else {
        const noOfTokensWei = web3.utils.toWei(`${noOfTokens}`, 'ether')
        const noOfTokensFormatted = web3.utils.toBN(noOfTokensWei)

        const cryptoInWei = web3.utils.toWei(`${crypto}`)
        const cryptoInWeiFormatted = web3.utils.toBN(cryptoInWei)

        const estimatedGas = await presaleContract.methods
          .buyTokens(noOfTokensFormatted)
          .estimateGas({
            from: address,
            value: cryptoInWeiFormatted,
          })

        const buyTokens = await presaleContract.methods
          .buyTokens(noOfTokensFormatted)
          .send({
            from: address,
            value: cryptoInWeiFormatted,
          })

        const localTransactionStatus = await buyTokens.status

        setTransactionStatus(localTransactionStatus)

        if (localTransactionStatus) {
          setUserDetails(
            await presaleContract.methods.userDetails(address).call()
          )
          setTxnHash(buyTokens.transactionHash)
          setTotalPurchasedTokens(
            await presaleContract.methods.totalPurchasedTokens().call()
          )
          setTransactionProgress(false)
          setShowTokenBought(true)
          toast.success(
            <div className="text-white">Your transaction is successfull</div>
          )
          // currentTransationMutation.mutate({
          //   buyer_wallet_address: address,
          //   no_of_mct_tokens_bought: tokenAmount,
          //   mct_token_price:
          //     !currPhaseStatus || isNaN(Number(currPhaseStatus[1]) / 10 ** 18)
          //       ? 0
          //       : Number(currPhaseStatus[1]) / 10 ** 18,
          //   total_amount_paid_for_mct_tokens: cryptoToPay,
          //   mct_tokens_purchase_date: new Date().toISOString(),
          //   // enter the default phase below for the case when currPhaseStatus is undefined
          //   presale_phase: !currPhaseStatus ? 1 : currPhaseStatus[0],
          // })
          settokenAmount(null)
        } else {
          setTransactionProgress(false)
        }
      }

      // // after transaction done
      // uploadToBackend();
    } catch (error) {
      setTransactionProgress(false)
      let errorMessage = error.message.split('\n')[0]
      errorMessage = errorMessage.split(': ')[1]
      if (errorMessage === 'insufficient funds for gas * price + value') {
        errorMessage = 'Insufficient funds for transaction'
      } else if (errorMessage === 'Pausable') {
        errorMessage = 'Buy token functionality has been paused'
      }
      console.log(`Error in transaction: ${errorMessage}`)
      toast.error(
        <div className="text-white">
          Encountered error in transaction:{' '}
          <p style={{ color: 'black' }}>{errorMessage}.</p> Please try again
        </div>
      )
    }
  }
  // bg-[#d7d5d5]/[0.6]
  return (
    <>
      <Navbar />
      <div
        // className=" bg-cover bg-center mt-[5rem] pt-28 pb-6 #d7d5d5 "
        // style={{ backgroundImage: `url(${new20})` }}
        className="bg-[#ffffff] pt-28 pb-6 "
      >
        {/* flex flex-col justify-center */}
        <div className="px-2 py-2 pb-4  flex flex-col justify-center mx-4 md:w-1/2 lg:w-1/3 md:mx-auto newfont_h4 buy-card-container rounded-tl-[15px] rounded-br-[15px] ">
          <div className="text-lg my-2 flex items-center gap-2 ">
            <span className=" newfont_h4 font-semibold">1 MCT :</span>
            <span className="text-[#008037] newfont_h4 text-l">
              {isNaN(Number(currPhaseStatus[1]) / 10 ** 18) ? '' : ``}{' '}
            </span>
            <span className="text-[#008037] font-semibold newfont_h4 ">
              {isNaN(Number(currPhaseStatus[1]) / 10 ** 18)
                ? ''
                : `$${Number(currPhaseStatus[1]) / 10 ** 18} (USD)`}{' '}
            </span>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              console.log(tokenAmount)
              if (errText.length !== 0) {
                toast.error(<div className="text-white ">{errText}</div>)
              }
              exchange(tokenAmount, cryptoToPay)
            }}
            className="flex flex-col justify-center"
          >
            <label className="newfont_small text-[#000] pb-1 mb-2:">
              Enter Number of Tokens
            </label>
            <input
              id="tokenAmount"
              type="number"
              placeholder="1000 tokens minimum"
              value={tokenAmount ? tokenAmount : ''}
              min={0}
              // max={}
              onKeyDown={(event) => {
                if (
                  event.key === '.' ||
                  event.key === 'e' ||
                  event.key === '-'
                ) {
                  event.preventDefault()
                }
              }}
              disabled={
                (chain && chain.id !== targetChainId) ||
                Number(currPhaseStatus[1]) / 10 ** 18 === 0 ||
                isNaN(Number(currPhaseStatus[1]) / 10 ** 18)
              }
              onChange={handleNumberOfTokens}
              className=" newfont_small rounded p-2 mb-4 border-[1px] border-[#004aad]"
            />
            <div className="flex flex-wrap items-center gap-2 ">
              <label className="newfont_small text-[#000]  mb-2 ">
                Amount to Pay (ETH) :{' '}
              </label>
              <div className="flex justify-center flex-wrap text-sm items-center pb-2">
                <span className="text-[#008037] newfont_small font-semibold">
                  {cryptoToPay ? cryptoToPay : ''}{' '}
                </span>
                {userDetails &&
                  tokenAmount &&
                  isConnected &&
                  tokenAmount >= 1000 &&
                  !cryptoToPay && (
                    <div className="flex justify-center flex-wrap text-sm items-center ">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                      <span className="text-xs">
                        Fetching details, please wait...
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {/* <input
              readOnly
              className="rounded p-2 mb-4 border-[1px] outline-none border-[#004aad]"
              value={cryptoToPay ? cryptoToPay : ''}
            /> */}

            <div className=" gap-2 mb-2 mt-2 items-center">
              <input
                type="checkbox"
                id="user_agreement"
                name="user_agreement"
                value={userAggrementAccepted}
                onChange={(e) => setUserAggrementAccepted(e.target.checked)}
                className="cursor-pointer mr-2 "
                disabled={
                  (chain && chain.id !== targetChainId) ||
                  Number(currPhaseStatus[1]) / 10 ** 18 === 0 ||
                  isNaN(Number(currPhaseStatus[1]) / 10 ** 18)
                }
              />
              <label for="user_agreement" className="text-sm newfont_h3 ">
                I confirm that I have read, understood, and agree to abide by
                the terms and conditions outlined in{' '}
                <a
                  href={
                    process.env.REACT_APP_MCC_MCT_DOC_TOKEN_HOLDER_AGREEMENT
                  }
                  target="_blank"
                  className="cursor-pointer text-[#004AAD] underline font-semibold"
                  rel="noreferrer"
                >
                  MCT Tokenholder Agreement
                </a>
              </label>
            </div>
            <div className="flex justify-center">
              <button
                // onClick={(e) => {
                //   e.preventDefault()
                //   console.log(tokenAmount)
                //   // if (errText.length !== 0) {
                //   //   toast.error(<div className="text-white ">{errText}</div>)
                //   // }
                //   // exchange(tokenAmount, cryptoToPay)
                // }}
                disabled={
                  !userAggrementAccepted ||
                  tokenAmount < 1000 ||
                  !isConnected ||
                  !cryptoToPay ||
                  (chain && chain.id !== targetChainId)
                }
                className={`newfont_h2 font-bold  " ${
                  !userAggrementAccepted ||
                  tokenAmount < 1000 ||
                  !isConnected ||
                  !cryptoToPay ||
                  (chain && chain.id !== targetChainId)
                    ? 'bg-gray-200 text-black font-medium'
                    : 'text-white bg-[#008037] border-[#008037]'
                }  w-full md:w-[200px] lg:w-[100%]  px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px]`}
              >
                Buy MCT
              </button>
            </div>
          </form>
        </div>
        <div className="buy-card-container flex flex-col justify-center md:w-1/2 lg:w-1/3 mx-auto mt-2">
          <div className="py-2 px-2  text-black newfont_small  ">
            {/* buy-card-container bg-[#004aad] */}
            {/* border border-[#008037] */}
            <div className="flex gap-4">
              <div>
                Your wallet details:
                <span className=" newfont_small text-[#004AAD]">
                  {address ? ` ` : ' Please connect Metamask to view details.'}
                </span>
              </div>
            </div>
          </div>
          <div className="   px-2 font_new mb-2  newfont_small   ">
            {/* md:w-2/3 lg:w-1/2 mx-4 md:mx-auto  */}
            <div className=" flex text-black gap-1 ">
              <div>
                <span className="">Address: </span>
              </div>
              <div className="flex justify-center items-center">
                <span className="newfont_h3_1 justify-center text-[#004AAD]">
                  {address ? ` ${address}` : ' '}
                </span>
              </div>
            </div>
            <div className="newfont_small ">
              <span className="">Tokens purchased:</span>
              <span className="newfont_h3_1  text-[#004AAD] ">
                {chain && chain.id === targetChainId && userDetails
                  ? ` ${parseInt(userDetails.purchasedTokens / 10 ** 18, 10)}`
                  : ' '}
              </span>
            </div>
          </div>
        </div>
        {(transactionProgress || showTokenBought) && (
          <Modal
            isOpen={transactionProgress || showTokenBought}
            showPrimary={false}
            modalWidth="w-1/2"
            showSecondary={false}
            showCloseIcon={!transactionProgress}
            handleClose={() => {
              if (transactionProgress) return
              else {
                setShowTokenBought(false)
              }
            }}
          >
            <div className="p-4 mx-4 text-md">
              {transactionProgress && (
                <div className="flex justify-center gap-2 text-sm items-center font-normal">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                  <span>Transaction in progress...</span>
                </div>
              )}
              {showTokenBought && (
                <>
                  <div>
                    Hello, your transaction is successfull, please view the
                    details at:
                  </div>
                  <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                    <a
                      href={`${explorerLink}${txnHash}`}
                      target="_blank"
                      className="text-blue-700"
                    >
                      {`${explorerLink}${txnHash}`}
                    </a>
                  </div>
                </>
              )}
            </div>
          </Modal>
        )}

        {transactionStatus !== null && !transactionStatus && (
          <Modal
            isOpen={transactionStatus !== null && !transactionStatus}
            modalWidth="w-1/2"
            showPrimary={false}
            showSecondary={false}
            handleClose={() => setTransactionStatus(null)}
          >
            <div className="p-4 mx-4 text-md">
              {!transactionStatus && (
                <>
                  <div>Transaction has failed, please view details at: </div>
                  <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                    <a
                      href={`${explorerLink}${txnHash}`}
                      target="_blank"
                      className="text-blue-700"
                    >
                      {`${explorerLink}${txnHash}`}
                    </a>
                  </div>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
      {/* <Footer /> */}
      {/* max-w-[322px] sm:max-w-[676px] lg:max-w-[978px]  */}
      <div className="text-left md:px-4 overflow-x-hidden w-full pb-2 px-4 mx-auto">
        <h1 className=" font-semibold text-[#888888] my-[10px] newfont_small">
          Disclaimer
        </h1>
        <p className="text-xs  text-[#888888] mb-[7px] text-justify newfont_h3">
          The information provided on this website is for general informational
          purposes only. It is not intended to be and should not be taken as
          legal, financial or investment advice. Investing in digital assets
          (carbon tokens) carries a high level of risk and may not be suitable
          for all investors. The value of these assets can be highly volatile
          and may fluctuate rapidly in response to market conditions. You should
          always conduct your own research and consult with a financial advisor
          before making any investment decisions. We are not responsible for any
          errors or omissions on this website or for any loss or damage of any
          kind incurred as a result of the use of any information contained on
          this website. By accessing and using this website, you acknowledge and
          accept the risks associated with investing in digital carbon tokens
          and agree to hold us harmless from any liability arising from any
          investment decisions you make based on the information provided on
          this website.
        </p>
      </div>
      <ToastContainer />
    </>
  )
}

export default TokenInfo
