import { Link } from 'react-router-dom'

import cosmosf from '../../../assets/images/cosmos/cosmos-f.png'
import cosmosh from '../../../assets/images/cosmos/cosmos-h.png'
import cosmose from '../../../assets/images/cosmos/cosmos-e.png'
import './style.css'
function CustomServiceCard({ imageUrl, heading, linkTo }) {
  return (
    <Link
      to={linkTo}
      onClick={() => window.scrollTo(0, 0)}
      className="text-center my-4 mx-2 lg:mx-4 "
    >
      <div
        className="bg-center rounded-full h-[140px] w-[140px] lg:h-[240px] lg:w-[240px] mx-auto bg-cover flex flex-col justify-center border-[1px] border-[#000]/[0] items-center shadow-[0_4px_5px_rgba(0,0,0,0.7)] product-card product-card:hover"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <h4 className="text-2xl text-[#000] font-bold mt-2 px-4">{heading}</h4>
    </Link>
  )
}

function Services() {
  return (
    <div className='ml-2 sm:ml-2 mr-2 sm:mr-2'>
    <div className="py-20 text-center px-4 max-w-full sm:max-w-full lg:max-w-[978px] mx-auto card-container">
      <h1 className="text-xl md:text-3xl font-semibold mb-[20px] newfont_h1 ">Products</h1>
      <p className="text-md newfont_h2 mb-[14px]">
        Our innovative products at MacroCosmos Creations (MCC) Private Limited
        is designed to address the critical issues of the triad: Climate change
        & Environmental sustainability (Cosmos-E), Food production systems &
        Food security (Cosmos-F) and Health & Wellness (Cosmos-H).
      </p>
      <div className="flex flex-col md:flex-row justify-center content-center md:justify-between newfont_h1 ">
        <CustomServiceCard
          imageUrl={cosmose}
          heading={'Cosmos-E'}
          linkTo="/products/cosmos-e"
        />
        <CustomServiceCard
          imageUrl={cosmosf}
          heading={'Cosmos-F'}
          linkTo="/products/cosmos-f"
        />
        <CustomServiceCard
          imageUrl={cosmosh}
          heading={'Cosmos-H'}
          linkTo="/products/cosmos-h"
        />
      </div>
    </div>
    </div>
  )
}

export default Services
