import Web3 from 'web3'
import { mainnet, goerli, sepolia } from '@wagmi/core/chains'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import presaleContractData from './PresaleContract.json'

const web3 = new Web3(Web3.givenProvider)

const { abi } = presaleContractData
const address = process.env.REACT_APP_MCC_MCT_PRESALE_BLOCKCHAIN_ADDRESS

let network
if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK === 'goerli') {
  network = goerli
} else if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK === 'sepolia') {
  network = sepolia
} else if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK === 'mainnet') {
  network = mainnet
}

const connector = new MetaMaskConnector({
  chains: [network],
  options: {
    shimDisconnect: false,
  },
})

const presaleContract = web3 ? new web3.eth.Contract(abi, address) : null

export { web3, presaleContract, connector }
