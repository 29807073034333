import React from 'react'
import { FAQ } from '../../../assets/content'
import Accordian from '../../../common/Accordian'
import "../../home/components/style.css"

const Faq = () => {
  return (
    <div className="p-4 pt-28 ">
      {FAQ.map((item) => {
        return (
          <>
            <h1 className="text-2xl font-bold mx-auto max-w-[80%] text-left my-4 newfont_h1">
              {item.heading}
            </h1>
            <div className='newfont_h2 text-left'>
              {item.faqs.map((faq, index) => {
                return (
                  <Accordian
                    key={`accordian__${index}`}
                    title={faq.question}
                    // content={faq.answer}
                    content={
                      <p className="max-w-full break-words justify-content tracking-tighter ">{faq.answer}</p>
                    }

                  />
                )
              })}
            </div>
          </>
        )
      })}
    </div>
  )
}

export default Faq
