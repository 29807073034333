import { useState } from 'react'
import Banner from './components/Banner'
import Blogs from './components/Blogs'
import SocialMedia from './components/SocialMedia'
import Services from './components/Services'
import Testimonials from './components/Testimonials'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import Announcement from './components/Announcement'
import Ad from "./components/Ad"

function Home() {
  const [isModalOpen, setModalOpen] = useState(false)
  return (
    <div className="relative overflow-x-hidden text-center w-screen flex flex-col">
      <Navbar />
      <Announcement/> 
      <Banner isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
      {/* <Announcement/>  */}
      <Ad/>
      <Services />
      <Testimonials />
      <Blogs />
      <SocialMedia />
      <Footer />
    </div>
  )
}

export default Home
