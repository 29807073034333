import React, { useEffect, useRef } from 'react'

import './style.css'

// function CustomTestimonialCard({ name, designation, testimonial }) {
//   return (
//     <div className="flex w-full lg:w-[50%] bg-[#82A800] my-4 sm:mx-2 rounded-tr-[35px] rounded-bl-[35px] px-2 py-4 lg:mx-4 shadow-[0_2px_5px_rgba(0,0,0,0.1)]">
//       <div className="px-2 flex flex-col justify-between">
//         <q className="px-2 text-base lg:text-lg text-justify text-white newfont_h3">
//           {testimonial}
//         </q>
//         <h6 className="text-xs text-right mt-4">
//           <span className="text-md text-left font-bold newfont_h3">{name}</span>
//           <span className="text-white newfont_h3">{` / ${designation}`}</span>
//         </h6>
//       </div>
//     </div>
//   )
// }

// function Testimonials() {
//   return (
//     <div className="bg-[#1F1F1F]">
//       <div className="py-20 text-center px-4 max-w-[90%] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
//         <h1 className="text-xl md:text-3xl text-white newfont_h1 font-semibold mb-[20px]">
//           What People Say
//         </h1>
//         <div className="flex flex-col lg:flex-row justify-center content-center md:justify-between newfont_h2">
//           <CustomTestimonialCard
//             name="Gro Harlem Brundtland"
//             designation="Ex-Norwegian Prime Minister"
//             testimonial="Sustainable development is the development that meets the needs of the present without compromising the ability of future generations to meet their own needs."
//           />
//           <CustomTestimonialCard
//             name="António Guterres"
//             designation="UN Secretary-General"
//             testimonial="Making peace with nature is the defining task of the 21st century.  It must be the top, top priority for everyone, everywhere."
//           />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Testimonials

import videoBg from '../../../assets/images/videos/MCT.mp4'

function Testimonials() {
  return (
    <div className='ml-2 sm:ml-2 mr-2 sm:mr-2'>
    <div className="relative bg-[#fafafa] pt-4 pb-4 ">
      <div className="video max-w-full sm:max-w-full lg:max-w-[978px] mx-auto">
        <video autoPlay muted loop className="videocard">
          <source
            src="https://mcc-public-docs.s3.amazonaws.com/MCC+-+MCT.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="text-overlay absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <h2 className="text-white newfont_h1_big px-4 text-center" style={{ maxHeight: '7rem' }}>
          Minimize your carbon footprint with MCT and maximize your impact!
        </h2>
      </div>
    </div>
    </div>
  );
}

export default Testimonials;




