import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SocialMedia from '../../common/SocialMedia'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'

import { submitForm } from './presaleSignup'
import "../../screens/home/components/style.css"

function ContactPage() {
  const [formState, setFormState] = useState({
    fullName: '',
    phoneNumber: '',
    emailID: '',
    verifiedEmail: '',
    telegramID: '',
    discordID: '',
    message: '',
  })
  const presaleMutation = useMutation(submitForm, {
    onSuccess: () => {
      setFormState({
        fullName: '',
        phoneNumber: '',
        emailID: '',
        verifiedEmail: '',
        telegramID: '',
        discordID: '',
        message: '',
      })
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    presaleMutation.mutate(
      Object.fromEntries(
        Object.entries(formState).filter(
          ([key]) => !key.includes('verifiedEmail')
        )
      )
    )
  }

  const handleVerifyEmail = () => {
    let email = document.getElementById('email')
    let verifiedEmail = document.getElementById('verifyEmail')
    if (email.value !== verifiedEmail.value) {
      verifiedEmail.setCustomValidity("Given emails don't match.")
    } else {
      verifiedEmail.setCustomValidity('')
    }
  }

  return (
    <>
      <Navbar />
      <form
      // className="pt-28 px-4 mx-auto mb-[2%] lg:my-[4%] max-w-[322px]  newfont_h2"
        className=" mt-28 md:mt-24 lg:mt-24 my-[2%] lg:my-[4%] text-xl lg:text-2xl px-4 mx-auto"
        onSubmit={handleSubmit}
      >
        <section className=" newfont_h2 text-gray-700 body-font">
          <div className="text-justify my-[8%] md:my-[2%] lg:w-1/2 md:w-2/3 mx-auto">
            <p className="">
              <span className="font-bold">
                {'MacroCosmos Carbon Token (MCT)'}
              </span>
              <span>
                {
                  ' is a digital token that is the backbone of our blockchain-based carbon offset management platform. The MCT token allows for secure and seamless buying, selling, and retirement of carbon credits without the risk of double counting or fraud. If you are interested in buying MCT token, please'
                }
              </span>
              <span className="font-bold">{' sign up for MCT Presale'}</span>
              <span>
                {
                  ' (launching soon) and join us in the journey towards a more sustainable future!'
                }
              </span>
            </p>
          </div>
        </section>
        <section className=" newfont_h2 text-gray-700 body-font relative">
          <div className="pb-[2%]">
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="fullName"
                      className=" leading-7 text-sm text-gray-600"
                    >
                      Full Name *
                    </label>
                    <input
                      type="text"
                      value={formState.fullName}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, fullName: e.target.value }
                        })
                      }
                      required={true}
                      id="fullName"
                      name="fullName"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="phoneNumber"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Phone Number *
                    </label>
                    <input
                      type="tel"
                      value={formState.phoneNumber}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, phoneNumber: e.target.value }
                        })
                      }
                      required={true}
                      id="phoneNumber"
                      name="phoneNumber"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Email *
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={formState.emailID}
                      onChange={(e) => {
                        handleVerifyEmail()
                        setFormState((formState) => {
                          return { ...formState, emailID: e.target.value }
                        })
                      }}
                      required={true}
                      name="email"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="verifyEmail"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Verify Email *
                    </label>
                    <input
                      type="email"
                      id="verifyEmail"
                      value={formState.verifiedEmail}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, verifiedEmail: e.target.value }
                        })
                      }
                      required={true}
                      onKeyUp={handleVerifyEmail}
                      name="verifyEmail"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="discordId"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Discord Id
                    </label>
                    <input
                      type="text"
                      id="discordId"
                      value={formState.discordID}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, discordID: e.target.value }
                        })
                      }
                      name="discordId"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="telegramId"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Telegram Id
                    </label>
                    <input
                      type="text"
                      id="telegramId"
                      value={formState.telegramID}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, telegramID: e.target.value }
                        })
                      }
                      name="telegramId"
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      What sparked your interest in MacroCosmos Carbon Token? *
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formState.message}
                      onChange={(e) =>
                        setFormState((formState) => {
                          return { ...formState, message: e.target.value }
                        })
                      }
                      required={true}
                      maxLength={500}
                      className="w-full rounded border border-[#004AAD] focus:border-[#82A800] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full" type="submit">
                  <button className="w-[50%] md:w-[45%] lg:w-[40%] text-[#ffffff] font-semibold bg-[#008037] px-[25px] py-[10px] border-[1px] rounded-tl-[15px] rounded-br-[15px] mx-2 mb-[10px] newfont_h2 hover:bg-[#006329]">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <ToastContainer />
      <SocialMedia />
      <Footer />
    </>
  )
}

export default ContactPage
