
import healthWellness from '../../../../assets/images/sectors/bgImgs/new_health6.jpg'

import '../../../home/components/style.css'

const Health = () => {
  return (
    <>
      <div className="flex flex-col text-left pt-36 px-4 self-center mb-4 mt-[-5rem]">
        <div
          // bg-[length:100%_auto hii]
          className="flex w-screen bg-center  bg-cover h-[25vh] lg:h-[70vh] bg-no-repeat"
          style={{ backgroundImage: `url(${healthWellness})` }}
        ></div>
        <h1 className="font-medium text-center mt-2 pt-2 text-white leading-tight text-xl lg:text-2xl lg:text-3xl newfont_h1">
          {/* Climate Change and Environmental Sustainability */}
          <span className="text-[#004AAD]">Health</span>
          <span className="text-[#00C2CB]">{` and `}</span>
          <span className="text-[#008037]">Wellness</span>
        </h1>
      </div>
      <section className="p-4 pt-0 max-w-full sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <div className="px-2 md:px-4  text-justify  ">
          <div>
            <p className="p-4 pt-0 pl-0 newfont_h2">
              MCC's commitment to promoting sustainability in the environment
              and food production is complemented by our dedication to the
              Health and Wellness sector. Within this domain, we are steadfast
              in our pursuit of providing comprehensive services in the areas of
              general health, nutrition, and mindfulness practice. Moreover, we
              are pleased to offer Voice therapy as an additional service under
              our esteemed banner, owing to the expertise of our co-founder, Dr.
              Ketaki Vasant Phadke, PhD, who is a highly accomplished voice
              therapist.
              <br />
              <br />
              Voice therapy is a specialized form of therapy that is designed to
              improve the health and function of the vocal system. It can be
              beneficial for individuals of all ages and backgrounds, including
              professional singers, actors, and public speakers, as well as
              those who are experiencing voice disorders due to injury or
              illness. One of the primary benefits of voice therapy is improved
              vocal clarity and strength, which can lead to greater confidence
              and more effective communication. Additionally, voice therapy can
              help to reduce the risk of vocal injury, improve breath control,
              and reduce muscle tension in the neck and shoulders. Voice therapy
              can also be helpful in the treatment of a range of voice
              disorders, including vocal nodules, polyps, and paralysis. Through
              a combination of exercises, education, and behavioural
              modifications, a qualified voice therapist can help individuals to
              overcome these challenges and achieve optimal vocal health.
              <br />
              <br />
              The Voice Wellness Centre™ which is a unit of MCC is led by Dr.
              Ketaki Vasant Phadke PhD, aims to
              <br />
              <ul className="list-disc list-outside">
                <p className="pl-8">
                  <li>
                    Excel in Voice Clinical Services (Diagnosis and Treatment of
                    Voice Problems){' '}
                  </li>
                  <li>Incorporate Research Activities </li>
                  <li>
                    {' '}
                    Provide Academic and Learning Tools related to voice science
                    and vocal health
                  </li>
                </p>
              </ul>
              <br />
              For more information related to the Voice services,{' '}
              <span
                onClick={() =>
                  window.open(
                    'https://drive.google.com/file/d/1xpVIDFj9jw5V76Y1RLbZO8Tb7zceoK3T/view?usp=sharing',
                    '__blank'
                  )
                }
                className="text-left text-wrap text-md text-[#82A800] font-normal cursor-pointer"
              >
                Click Here
              </span>
              .
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Health
