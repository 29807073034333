// react imports
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

// third part library imports
import {
  WagmiConfig,
  createClient,
  configureChains,
  mainnet,
  goerli,
  sepolia
} from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// screen page imports
import App from './App'
import ErrorPage from './screens/error'
import ProductsPage from './screens/products'
import ContactPage from './screens/contact'
import AboutPage from './screens/about'
import FaqIndex from './screens/faq'
import HowToBuy from './screens/howToBuy'
import PresaleSignup from './screens/presaleSignup'
import TokenInfo from './screens/home/components/TokenInfo'

// page component imports
import ClimateAndEnvironment from './screens/sectors/climateChangeAndEnvironmentalSustainability'

// styling related imports
import './index.css'
import CookiesIndex from './screens/cookies'
import TermsAndConditionsIndex from './screens/termsAndConditions'
import PrivacyIndex from './screens/privacyPolicy'

let network;
if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK == "goerli") {
  network = goerli
} else if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK == "sepolia") {
  network = sepolia
} else if (process.env.REACT_APP_MCC_MCT_BLOCKCHAIN_NETWORK == "mainnet") {
  network = mainnet
} 

const { chains, provider, webSocketProvider } = configureChains(
  [network], // chain (mainnet, goerli) taken from env variable
  [
    infuraProvider({ apiKey: process.env.REACT_APP_API_PUBLIC_KEY_ETHEREUM }),
    publicProvider(),
  ]
)

// Client
const client = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
  webSocketProvider,
})

// ? this is where we define all the routes related to the app, try to abstract this when codebase gets huge
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sector/:sectorName',
    element: <ClimateAndEnvironment />,
  },
  {
    path: '/products/:productName',
    element: <ProductsPage />,
  },
  {
    path: '/contact',
    element: <ContactPage />,
  },
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: '/faq',
    element: <FaqIndex />,
  },
  {
    path: '/cookies',
    element: <CookiesIndex />,
  },
  {
    path: '/terms',
    element: <TermsAndConditionsIndex />,
  },
  {
    path: '/privacy',
    element: <PrivacyIndex />,
  },
  {
    path: '/presale-signup',
    element: <PresaleSignup />,
  },
  {
    path: '/how-to-buy',
    element: <HowToBuy />,
  },
  {
    path: '/buymct',
    element: <TokenInfo />,
  },
])

// react query client created here
const queryClient = new QueryClient()

// providers and rendering
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={client}>
        <RouterProvider router={router} />
      </WagmiConfig>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
