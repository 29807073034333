import { toast } from 'react-toastify'

export const submitForm = async (payload) => {
  const data = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/contactForm`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(payload),
    }
  )
  if (data.ok) {
    toast.success(
      <div className="text-white">You will be contacted soon by our team</div>
    )
  }
  return data.json()
}
