import foodSecurity from '../../../../assets/images/sectors/bgImgs/widescreen_food_production_bg.png'
import cropPlanning from '../../../../assets/images/sectors/crop_planning.png'
import cropMonitoring from '../../../../assets/images/sectors/crop_monitoring.png'
import harvest from '../../../../assets/images/sectors/harvest.png'
// import yieldMonitoring from '../../../../assets/images/sectors/yield_monitoring.png'
import Modal from '../../../../common/Modal'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../home/components/style.css'
import yieldMonitoring from '../../../../assets/images/sectors/yield_monitoring-2.png'
import newfood4 from '../../../../assets/images/sectors/bgImgs/food4.jpg'
const Food = () => {
  const [foodModal, setFoodModal] = useState(false)
  const [foodContent, setFoodContent] = useState('')
  const [foodTitle, setFoodTitle] = useState('')

  const renderFood = () => {
    return (
      <Modal
        isOpen={foodModal}
        headerTitle={foodTitle}
        modalWidth="w-3/5 newfont_h2"
        primaryButtonText="Okay"
        handleClose={() => {
          setFoodModal(false)
        }}
        handlePrimaryClick={() => {
          setFoodModal(false)
        }}
        showPrimary={false}
        showSecondary={false}
      >
        <div className="flex flex-col lg:flex-row mt-2 items-center p-4 text-justify newfont_h2">
          {foodContent}
        </div>
      </Modal>
    )
  }
  return (
    <>
      <div className="flex flex-col text-left px-4 self-center mb-4 mt-[-6rem]">
        <div
          // bg-[length:100%_auto]
          className="flex w-screen bg-center  bg-cover h-[55vh] lg:h-[70vh] bg-no-repeat"
          style={{ backgroundImage: `url(${newfood4})` }}
        >
           {/* <img src='../../../../assets/images/sectors/gif_food.mp4' alt="Animated GIF" className="w-full h-full object-cover" /> */}
        </div>
        <h1 className="font-medium text-center mt-2 pt-2  text-white leading-tight text-xl lg:text-2xl lg:text-3xl newfont_h1">
          {/* Climate Change and Environmental Sustainability */}
          <span className="text-[#004AAD]">Food Production Systems</span>
          <span className="text-[#00C2CB]">{` and `}</span>
          <span className="text-[#008037]">Food Security</span>
        </h1>
      </div>
      <section className="p-4 pt-0 max-w-full sm:max-w-[676px] lg:max-w-[978px] mx-auto">
        <div className="px-2 md:px-4  text-justify  lg:text-lg">
          <p className="p-4 pt-0 pl-0 newfont_h2">
            We are currently developing advanced decision support systems for
            optimizing crop planning, management, and monitoring, as well as
            predicting harvest time and crop yield. While these features are not
            yet available, we are committed to providing the most advanced and
            cutting-edge solutions to support sustainable agriculture and food
            security.
            <br />
            <br />
            Meanwhile, we invite you to explore our existing services and learn
            more about our commitment to innovation and sustainability. For more
            information, please{' '}
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/sector/climate-change-environmental-sustainability"
              className="text-[#82A800]"
            >
              Click Here
            </Link>
            .
            <br />
            <br />
            We also believe in the power of education and knowledge-sharing to
            support the future of food systems. Currently, we offer a range of
            resources on our website to help farmers and consumers stay informed
            about the latest trends, technologies, and best practices in
            sustainable agriculture and food security.
          </p>
        </div>
      </section>
      <div className="mb-4 w-[80%] xl:w-[70%] mx-auto max-w-[1440px] text-xs sm:text-sm lg:text-lg self-center grid grid-cols-2 lg:grid-cols-4 gap-4 place-content-center place-items-center newfont_h2">
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer"
          onClick={() => {
            setFoodModal(true)
            setFoodTitle('Crop Planning')
            setFoodContent(
              'Crop planning is the process of deciding what, where, and when to plant crops to achieve optimal yields, manage risks, and meet market demands. It involves making decisions about crop rotation, soil fertility, pest management, and harvesting to increase productivity, reduce costs, and improve sustainability.'
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={cropPlanning}
            alt="cropPlanning"
            className="circular-image  object-contain"
          />
          </div>
          <p className="font-bold">Crop Planning</p>
        </div>
        <div
          className="text-center flex flex-col items-center  mx-2 cursor-pointer"
          onClick={() => {
            setFoodModal(true)
            setFoodTitle('Crop Monitoring')
            setFoodContent(
              'Crop management and monitoring are essential for optimizing yields, reducing costs, and ensuring sustainability in farming. Practices like irrigation, fertilization, and pest control help maintain crop growth and health, while regular observation and assessment identify areas for improvement. Technology, such as precision agriculture tools and crop management software, enables data-driven decision-making and streamlines operations, leading to improved profitability for farmers.'
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={cropMonitoring}
            alt="cropMonitoring"
            className="circular-image  pt-1 object-contain"
          />
          </div>
          <p className="font-bold">Crop Monitoring</p>
        </div>
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer"
          onClick={() => {
            setFoodModal(true)
            setFoodTitle('Harvesting Time')
            setFoodContent(
              'Harvesting time is when crops are mature and ready to be gathered from fields for use or storage. The timing affects yield, quality, and efficiency. Harvesting too early or late can reduce yields and quality, while harvesting at the right time ensures maximum yield and quality. Factors affecting timing include crop type, weather, and market demand.'
            )
          }}
        >
        <div className='circular-image-container circular-ring'>
          <img
            src={harvest}
            alt="harvest"
            className="circular-image  object-contain"
          />
          </div>
          <p className="font-bold">Harvesting Time</p>
        </div>
        <div
          className="text-center flex flex-col items-center mx-2 cursor-pointer"
          onClick={() => {
            setFoodModal(true)
            setFoodTitle('Yield Monitoring')
            setFoodContent(
              'Yield prediction is the process of estimating the crop amount that will be harvested from a specific area of land, based on factors like soil fertility, climate, crop type, planting density, and management practices. Accurate yield prediction is important for farmers, processors, and policymakers to ensure production goals are met, and food security is maintained.'
            )
          }}
        >
         <div className='circular-image-container circular-ring'>
          <img
            src={yieldMonitoring}
            alt="yieldMonitoring"
            className="circular-image  object-contain"
          />
          </div>
          <p className="font-bold">Yield Monitoring</p>
        </div>
      </div>
      {foodModal && renderFood()}
    </>
  )
}

export default Food
