export const FAQ = [
  {
    heading: 'General Questions',
    faqs: [
      {
        question: 'What are carbon tokens?',
        answer:
          'Carbon tokens are digital assets that represent a specific amount of carbon credits. Carbon credits are used by companies and governments to offset their greenhouse gas emissions by investing in projects that reduce or remove carbon from the atmosphere.',
      },
      {
        question: 'How do carbon tokens work?',
        answer:
          'Carbon tokens are created by issuing a set number of tokens that correspond to a particular amount of carbon credits. These tokens can be bought, sold, and traded on a blockchain-based platform, providing transparency and efficiency in the carbon credit market.',
      },
      {
        question: 'What are the benefits of buying carbon tokens?',
        answer:
          'By purchasing carbon tokens, individuals and organizations can offset their carbon footprint and contribute to the global efforts against climate change. Furthermore, investing in carbon tokens can be viewed as supporting the growth of the carbon credit market, which is expected to expand in the future.',
      },
    ],
  },
  {
    heading: 'Questions related to Macrocosmos Carbon Token (MCT)',
    faqs: [
      {
        question: 'What is MCT? ',
        answer:
          'MCT refers to the Macrocosmos Carbon Token, a digital token that serves as the foundation for our blockchain-based carbon offset management platform. This platform utilizes MCT tokens to facilitate secure and transparent trading of carbon credits. By purchasing MCT tokens, you directly support the reduction of carbon footprints and greenhouse gas emissions, promoting a sustainable future.',
      },
      {
        question: 'How does investing in the MCT help reduce carbon footprint?',
        answer:
          'Investing in MCT enables you to contribute to sustainable projects aimed at reducing greenhouse gas emissions and achieving the goal of net-zero emissions. The MCT platform allows for the buying, selling, and retirement of carbon credits, ensuring the avoidance of double counting and supporting high-quality projects. When you purchase MCT, you receive a blockchain-based digital certificate that validates your offset and commitment to the net-zero objective.',
      },
      {
        question:
          'Is there a minimum and maximum limit for purchasing MCT tokens?',
        answer:
          'Yes, the minimum purchase amount for MCT tokens is 1000 tokens. However, there is no maximum limit on the amount of MCT tokens you can purchase.',
      },
      {
        question:
          'Can I purchase MCT tokens using cryptocurrency or fiat currency?',
        answer:
          'Currently, you can only purchase MCT tokens using ETH (Ethereum) through the Metamask wallet. Fiat payment options are not available for purchasing MCT tokens at the moment.',
      },
      {
        question: 'Do I need to claim my MCT tokens, and if so, when?',
        answer:
          'Yes, you will need to claim your MCT tokens. Instructions for claiming your tokens will be provided on the website (www.macrocosmoscreations.com) after the presale phase is completed. Please stay updated through our website for further instructions on how to claim your tokens.',
      },
    ],
  },
]

export const OUR_CARBON_TOKEN_CONTENT =
  'The MCT is a digital token that is the backbone of our blockchain-based carbon offset management platform. Our blockchain-based platform utilizes the MCT token as a means of facilitating carbon credit trading, making it more efficient and transparent. The MCT token allows for secure and seamless buying, selling, and retirement of carbon credits without the risk of double counting or fraud. By buying MCT token, you are directly supporting the reduction of carbon footprint and greenhouse gas emissions, thereby contributing to a sustainable future'

export const COOKIES_CONTENT = {
  DEFINITION:
    "Cookies are small text files that a website stores on a user's device (such as a computer, smartphone, or tablet) when they visit the site, they are used to remember your preferences and actions on the website and help provide you with a better and more personalized experience.",
  TYPES_OF_COOKIES: [
    {
      title: 'Essential Cookies:',
      desc: 'These are necessary for the basic functioning of a website and cannot be disabled by the user.',
    },
    {
      title: 'Functional Cookies:',
      desc: "They are used to remember the user's preferences and choices on the website, such as language preference, or items in a shopping cart. These cookies improve the user's experience and help the website function properly.",
    },
    {
      title: 'Analytical Performance Cookies:',
      desc: 'These cookies collect data about user behaviour on a website, such as page visits, time spent, and referral sources. The information is used to analyse and improve website performance.',
    },
    {
      title: 'Advertising Cookies:',
      desc: 'These cookies track user behaviour and preferences to deliver targeted ads to the user. The information collected by these cookies may be shared with trusted third-party partners such as advertisers.',
    },
  ],
  HOW_TO_MANAGE: [
    'You can adjust settings on your browser to control the cookies stored on your device. You can set your browser to refuse all cookies, or to only allow essential cookies. However, please note that if you choose to disable cookies, some parts of our website may not function properly.',
    'We may update this Cookies Policy from time to time for operational, legal or regulatory reasons.',
  ],
}

export const TERMS_AND_CONDITIONS = [
  {
    title: 'General Terms:',
    desc: 'By accessing or using our website and services, you agree to be bound by these Terms and Conditions.',
  },
  {
    title: 'Use of Our Services:',
    desc: 'Our services are provided on an “as is” and “as available” basis. We reserve the right to modify, suspend, or terminate our services at any time.',
  },
  {
    title: 'Intellectual Property:',
    desc: 'Our website and services are protected by intellectual property laws. You may not use our content or services without our permission.',
  },
  {
    title: 'Prohibited Conduct:',
    desc: 'You may not use our website and services for any illegal or unauthorized purpose. You may not interfere with our website or services or attempt to access them using unauthorized means.',
  },
  {
    title: 'Limitation of Liability:',
    desc: 'We are not liable for any damages arising out of or related to your use of our website and services. In no event will our liability exceed the amount paid by you for our services.',
  },
  {
    title: 'Dispute Resolution:',
    desc: 'Any disputes arising out of or related to these Terms and Conditions will be resolved through arbitration in accordance with the rules of the Indian Arbitration and Conciliation Act.',
  },
  {
    title: 'Governing Law:',
    desc: 'These Terms and Conditions will be governed by the laws of India.',
  },
  {
    title: 'Changes to These Terms and Conditions:',
    desc: 'We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on our website.',
  },
]

export const PRIVACY_CONTENT = {
  what_we_collect: [
    'Personal information that links back to an individual, e.g., name, date of birth, and other personal identification numbers.',
    'Contact information, e.g., address, phone number and email address, and wallet address.',
    'Technical information, e.g., IP address for API services and logins.',
    'Statistical data, e.g., hits to the website.',
    'Other information relevant to customer surveys and/or offers.',
  ],
  personal_data: {
    main_ways: [
      'When you engage with our products and services',
      'When you create an account with us',
    ],
    other_ways: [
      'Communications with you via telephone, letter, fax, and email',
      'When you visit our website',
      'When you contact us in person',
      'When we contact you in person',
      'When we collect information about you from third parties and other channels including our support helpdesk.',
      'Possible extrapolation of other information by processing the data.',
    ],
  },
  waht_we_do: [
    'Internal record keeping.',
    'To improve our products and services.',
    'For sending promotional emails about new products, special offers or other information periodically',
    'To contact you by email, phone or fax for market research purposes.',
    'To comply with legal and regulatory requirements or in case of a dispute between you & our company.',
  ],
  controlling_your_personal_info: [
    'Whenever you are asked to fill in a form on the website, look for the option to opt out of providing any personal information that is not required.',
    'If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at',
  ],
  whom_do_we_disclose_personal_data: [
    'Data warehouses',
    'IT service providers;',
    'Data analytics and/or marketing agency',
    'Legal bodies as permitted or required by law such as in compliance with a warrant or subpoena issued by a court of competent jurisdiction and/or',
    'Regulatory authorities applicable to you and/or',
    'Safety and security personnel.',
  ],
  para_1:
    'In addition to the above, your Personal Data may also be disclosed or transferred to any of the Company’s actual and potential assignee, transferee or acquirer (including our affiliates and subsidiaries) or our business, assets or group companies, or in connection with any corporate restructuring or exercise including our restructuring to transfer the business, assets and/or liabilities. We shall take practical steps to ensure that their employees, officers, agents, consultants, contractors and other third parties mentioned above who are involved in the collection, use and disclosure of your Personal Data will observe and adhere to the terms of this Privacy Statement.',
  how_long_data_retains:
    'The Company may store data in global hosting providers with servers across various regions and we shall take all reasonable steps to ensure that all Personal Data is destroyed or permanently deleted when no longer required for the purpose of the company, and prepare a disposal schedule for inactive data after a 24-month period.',
  links_to_other_websites:
    'Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.',
  security: {
    first_para:
      'We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic',
    last_para:
      'Please note that this Privacy Policy may be amended from time to time in accordance with applicable laws and regulations and such variations may be applicable to you.',
  },
  contact_us:
    'For further inquiries or complaints in relation to our handling of your Personal Data or our Privacy Policy, please contact us at',
}

export const STEPS = [
  {
    step: 'Visit the official MacroCosmos Creations (MCC) homepage at www.macrocosmoscreations.com.',
  },
  {
    step: "On the homepage, locate and click on the 'Buy MCT' button. This will redirect you to the 'Buy MCT' page.",
  },
  {
    step: "Ensure that you have the MetaMask wallet installed on your browser. If you don't have it installed, you can download and set it up by following the instructions on the MetaMask website.",
  },
  {
    step: "Connect your MetaMask wallet to the website by clicking on the 'Connect to Metamask' option on the top right corner of the page. This will establish a connection between your wallet and the MCC platform.",
  },
  {
    step: "On the 'Buy MCT' page, enter the number of MCT tokens you wish to purchase. Note that there is a minimum requirement of 1000 tokens for each transaction.",
  },
  {
    step: 'The equivalent ETH price will be automatically calculated based on the number of tokens you want to buy and the current MCT token price during the presale phase.',
  },
  {
    step: 'Before proceeding with the purchase, it is important to thoroughly read the MCT Token Holder Agreement. Tick mark the checkbox to confirm that you have read, understood, and agree to abide by the terms and conditions outlined in the agreement.',
  },
  {
    step: 'MetaMask will open and prompt you to confirm the transaction details. Ensure that the equivalent ETH amount and gas fees are acceptable to you.',
  },
  {
    step: 'Complete the transaction by paying the equivalent ETH amount and any applicable gas fees as prompted by MetaMask.',
  },
  {
    step: 'Once the transaction is successfully processed, you will receive a message response on the MCC website confirming the completion of your purchase. Additionally, a link will be provided for you to access detailed information about the transaction.',
  },
]
