import Footer from '../../common/Footer'
import Navbar from '../../common/Navbar'

import { BsFillArrowRightCircleFill, BsBarChartSteps } from 'react-icons/bs'

import { STEPS } from '../../assets/content'
import '../home/components/style.css'

const HowToBuy = () => {
  return (
    <>
      <Navbar />
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-10 mx-8 md:mx-16 mt-28 md:mt-90  mb-8 ">
        <div class="p-6 text-lg flex items-center gap-4 font-semibold">
          <BsBarChartSteps className=" text-[60px] md:text-[24px] lg:text-[24px]  text-[#008037]" />
          <span className=" font-bold newfont_h2 ">
            By following these steps, you will be able to buy MCT tokens
            securely and efficiently.
          </span>
        </div>
        <ol class="flex flex-col mx-auto relative border-l  text-justify w-3/2 lg:w-1/2 my-8 newfont_h2">
          {STEPS.map((item) => {
            return (
              <li class="mb-10 ml-6">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white">
                  <BsFillArrowRightCircleFill className="text-2xl text-[#008037]" />
                </span>
                <p className="m-2">{item.step}</p>
              </li>
            )
          })}
        </ol>
      </div>

      <Footer />
    </>
  )
}

export default HowToBuy
