import { Link } from 'react-router-dom'
import EnquiryForm from './EnquiryForm'
import '../screens/home/components/style.css'

import { MdLocationOn } from 'react-icons/md'
import { FaFax, FaEnvelope } from 'react-icons/fa'
import '../screens/home/components/style.css'

function Footer() {
  return (
    <>
    {/* #1F1F1F  px-2 lg:px-4  md:px-4*/}
      <div className="bg-[#fafafa]">
        <div className="flex flex-col md:flex-row justify-between items-center pt-20  max-w-[300px] sm:max-w-[676px] lg:max-w-[978px] mx-auto">
          <div className='flex flex-col justify-center items-center mb-4 lg:mb-0'>
          <div className="lg:w-[100%] flex flex-col w-full ">
              <div>
                  <h2 className="text-lg lg:text-xl text-[#000000]/[0.9] font-semibold text-center newfont_h1 ">
              QUICK LINKS
            </h2>
              </div>
              <div className='flex flex-row mt-4'>
            <div className="flex flex-col text-left lg:text-left  md:text-left  ml-4 mr-20 lg:ml-12 lg:pr-16 ">
              <Link
                to="/faq"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                FAQ
              </Link>
              <div
                onClick={() =>
                  window.open(
                    'https://medium.com/@creationsmacrocosmos',
                    '__blank'
                  )
                }
                className=" text-wrap text-md text-[#888888] newfont_h2 cursor-pointer hover:text-[#000000]/[0.9] pb-2"
              >
                Blogs
              </div>
              <Link
                to="/about"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                About Us
              </Link>
              <Link
                to="/contact"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                Contact Us
              </Link>
              </div>
              <div className='flex flex-col text-left lg:text-left md:text-left '>
              <Link
                to="https://scrutify.super.site/mcc-audit"
                target="_blank"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                MCT Audit
              </Link>

              <Link
                to="/privacy"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                Privacy Policy
              </Link>
              <Link
                to="/cookies"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                Cookies Policy
              </Link>
              <Link
                to="/terms"
                onClick={() => window.scrollTo(0, 0)}
                className="text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9] pb-2"
              >
                Terms and Conditions
              </Link>

              {/* <h6
                className="text-wrap text-md text-[#82A800] font-normal cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://drive.google.com/file/d/1QauWBVHhGDHW6BxU2VpB2Q8BM3kpLd-n/view?usp=sharing",
                    "__blank"
                  )
                }
              >
                Token Holder Agreement
              </h6> */}
              {/* <h6 className="text-wrap text-md text-[#82A800] font-normal cursor-pointer">
                Site Map
              </h6> */}
            </div>
            </div>
          </div>
          <div className="pt-6 lg:pt-12 lg:mb-6">
            <h2 className="text-lg lg:text-xl text-[#000000]/[0.9] text-center font-semibold newfont_h1 mt-2 lg:mt-6 ">
              HOW TO REACH US
            </h2>
            <div className="mt-4 lg:mt-2">
              <div className="flex items-start py-2 lg:py-3 border-b-[1px] border-[#888888]">
                <FaFax size={15} color={'#888888'} />
                <h6 className="pl-4 text-left text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9]">
                  MacroCosmos Creations Pvt. Ltd.
                </h6>
              </div>
              <div className="flex items-start py-2 lg:py-3 border-b-[1px] border-[#888888] hover:text-[#000000]/[0.9]">
                <MdLocationOn size={15} color={'#888888'} />
                <h6 className="pl-4 text-left text-wrap text-md text-[#888888] newfont_h2 hover:text-[#000000]/[0.9]">
                  Banshankari, Bangalore, KA 560085 IN
                </h6>
              </div>
              <div className="flex items-start py-2 lg:py-3">
                <FaEnvelope size={15} color={'#888888'} />
                <h6 className="pl-4 text-left text-wrap text-md text-[#888888] newfont_h2 break-all hover:text-[#000000]/[0.9]">
                  contact@macrocosmoscreations.com
                </h6>
              </div>
            </div>
          </div>
          </div>
          <EnquiryForm />
        </div>
        <h4 className="text-[#888888] newfont_h2 py-5 md:px-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto text-center">
          Copyright &copy; 2023 - All Rights Reserved -
          <span className="cursor-pointer">{` MacroCosmos Creations Pvt. Ltd.`}</span>
        </h4>
      </div>
      {/* <div className="text-left md:px-4 max-w-[322px] sm:max-w-[676px] lg:max-w-[978px] mx-auto overflow-x-hidden w-full pb-2 px-4 mx-auto">
        <h1 className="text-lg md:text-xl font-semibold my-[10px] newfont_h1">
          DISCLAIMER
        </h1>
        <p className="text-xs  text-[#888888] mb-[7px] text-justify newfont_h3">
          The information provided on this website is for general informational
          purposes only. It is not intended to be and should not be taken as
          legal, financial or investment advice. Investing in digital assets
          (carbon tokens) carries a high level of risk and may not be suitable
          for all investors. The value of these assets can be highly volatile
          and may fluctuate rapidly in response to market conditions. You should
          always conduct your own research and consult with a financial advisor
          before making any investment decisions. We are not responsible for any
          errors or omissions on this website or for any loss or damage of any
          kind incurred as a result of the use of any information contained on
          this website. By accessing and using this website, you acknowledge and
          accept the risks associated with investing in digital carbon tokens
          and agree to hold us harmless from any liability arising from any
          investment decisions you make based on the information provided on
          this website.
        </p>
      </div> */}
      <div className="text-left md:px-4 mx-auto overflow-x-hidden w-full pb-2 px-4 mx-auto">
        <h1 className=" font-semibold text-[#888888] my-[10px] newfont_small">
          Disclaimer
        </h1>
        <p className="text-xs  text-[#888888] mb-[7px] text-justify newfont_h3">
          The information provided on this website is for general informational
          purposes only. It is not intended to be and should not be taken as
          legal, financial or investment advice. Investing in digital assets
          (carbon tokens) carries a high level of risk and may not be suitable
          for all investors. The value of these assets can be highly volatile
          and may fluctuate rapidly in response to market conditions. You should
          always conduct your own research and consult with a financial advisor
          before making any investment decisions. We are not responsible for any
          errors or omissions on this website or for any loss or damage of any
          kind incurred as a result of the use of any information contained on
          this website. By accessing and using this website, you acknowledge and
          accept the risks associated with investing in digital carbon tokens
          and agree to hold us harmless from any liability arising from any
          investment decisions you make based on the information provided on
          this website.
        </p>
      </div>
    </>
  )
}

export default Footer
