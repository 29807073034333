import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import TermsAndConditions from './components/TermsAndConditions'

function TermsAndConditionsIndex() {
  return (
    <div className="relative overflow-x-hidden text-center w-screen flex flex-col">
      <Navbar />
      <TermsAndConditions />
      <Footer />
    </div>
  )
}

export default TermsAndConditionsIndex
